import { makeAutoObservable } from 'mobx';
import ApiConstants from '../constants/ApiConstants';
import { HTTPGetRequest, HTTPRequest, HTTPPutRequest } from '../helpers';

class SubscriptionStore {
    LoginStore;
    AlertStore;
    MODULE_URL = `${ApiConstants.BASE_URL}subscription/`;

    subscriptionList = [];
    subscriptionListLoading = false;
    subscriptionUsersList = [];
    subscriptionUsersListLoading = false;
    addOnType = [];
    updateStatusSuccess = false;
    subscriptionTypeList = [];
    subscriptionPartnerList = [];
    downloadSuccess = false;
    addSubscriptionSuccess = false;
    downloadSubscriptionListLoading = false;
    downloadSubscriptionUsersLoading = false;

    setSubscriptionListLoading = bool => {
        this.subscriptionListLoading = bool;
    };

    setSubscriptionList = data => {
        this.subscriptionList = data;
    };

    setSubscriptionUsersListLoading = bool => {
        this.subscriptionUsersListLoading = bool;
    };

    setSubscriptionUsersList = data => {
        this.subscriptionUsersList = data;
    };

    setAddOnType = data => {
        this.addOnType = data?.add_on_types;
    };

    setUpdateStatusSuccess = bool => {
        this.updateStatusSuccess = bool;
    };

    setSubscriptionTypeList = data => {
        this.subscriptionTypeList = data?.subscription_types;
    };

    setSubscriptionPartnerList = data => {
        this.subscriptionPartnerList = data?.subscription_partners;
    };

    setDownloadSuccess = bool => {
        this.downloadSuccess = bool;
    };

    setAddSubscriptionSuccess = bool => {
        this.addSubscriptionSuccess = bool;
    };

    setDownloadSubscriptionListLoading = bool => {
        this.downloadSubscriptionListLoading = bool;
    };

    setDownloadSubscriptionUsersLoading = bool => {
        this.downloadSubscriptionUsersLoading = bool;
    };

    constructor(stores) {
        makeAutoObservable(this);
        this.LoginStore = stores.LoginStore;
        this.AlertStore = stores.AlertStore;
        this.userToken = this.LoginStore.userInfo.token;
        this.userId = this.LoginStore.userInfo.id;
    }

    getAddOnType = () => {
        HTTPGetRequest(`${this.MODULE_URL}addon-type`, {
            'x-stq-apiKey': this.userToken,
        })
            .then(res => {
                if (res.status === 200) {
                    this.setAddOnType(res?.response);
                } else {
                    this.setAddOnType([]);
                }
            })
            .catch(e => {
                this.setAddOnType([]);
            });
    };

    getSubscriptionList = data => {
        this.setSubscriptionListLoading(true);
        HTTPRequest(
            `${this.MODULE_URL}list-subscriptions`,
            {
                'x-stq-apiKey': this.userToken,
            },
            {
                page: data?.page || 1,
                search_key: data?.searchVal || '',
                status: data?.status || '',
                subs_type: data?.subscriptionType || '',
                subs_partner: data?.subscriptionPartner || '',
            }
        )
            .then(res => {
                this.setSubscriptionListLoading(false);
                if (res.status === 200) {
                    this.setSubscriptionList(res?.response);
                } else {
                    this.setSubscriptionList([]);
                }
            })
            .catch(e => {
                this.setSubscriptionList([]);
                this.setSubscriptionListLoading(false);
            });
    };

    getSubscriptionUsersList = data => {
        this.setSubscriptionUsersListLoading(true);
        HTTPRequest(
            `${this.MODULE_URL}list-subscriptions-users`,
            {
                'x-stq-apiKey': this.userToken,
            },
            {
                page: data?.page || 1,
                search_key: data?.searchVal || '',
                status: data?.status || '',
            }
        )
            .then(res => {
                this.setSubscriptionUsersListLoading(false);
                if (res.status === 200) {
                    this.setSubscriptionUsersList(res?.response);
                } else {
                    this.setSubscriptionUsersList([]);
                }
            })
            .catch(e => {
                this.setSubscriptionUsersList([]);
                this.setSubscriptionUsersListLoading(false);
            });
    };

    updateStatus = data => {
        this.setUpdateStatusSuccess(false);
        HTTPRequest(
            `${this.MODULE_URL}status`,
            {
                'x-stq-apiKey': this.userToken,
            },
            {
                subs_id: data?.subscriptionId,
                is_active: data?.status,
            }
        )
            .then(res => {
                if (res.status === 200) {
                    this.setUpdateStatusSuccess(true);
                    this.AlertStore.setAlert('success', res?.response?.message);
                } else {
                    this.setUpdateStatusSuccess(false);
                    this.AlertStore.setAlert('error', res?.response?.message);
                }
                this.setUpdateStatusSuccess(false);
            })
            .catch(e => {
                this.setUpdateStatusSuccess(false);
                this.AlertStore.setAlert('error', 'Error in updating Status');
            });
    };

    getSubscriptionTypeList = () => {
        HTTPGetRequest(`${this.MODULE_URL}subscription-type`, {
            'x-stq-apiKey': this.userToken,
        })
            .then(res => {
                if (res.status === 200) {
                    this.setSubscriptionTypeList(res?.response);
                } else {
                    this.setSubscriptionTypeList([]);
                }
            })
            .catch(e => {
                this.setSubscriptionTypeList([]);
            });
    };

    getSubscriptionPartnerList = () => {
        HTTPGetRequest(`${this.MODULE_URL}subscription-partners`, {
            'x-stq-apiKey': this.userToken,
        })
            .then(res => {
                if (res.status === 200) {
                    this.setSubscriptionPartnerList(res?.response);
                } else {
                    this.setSubscriptionPartnerList([]);
                }
            })
            .catch(e => {
                this.setSubscriptionPartnerList([]);
            });
    };

    downloadActivationCode = data => {
        this.setDownloadSuccess(false);
        HTTPRequest(
            `${this.MODULE_URL}download-activation-codes`,
            {
                'x-stq-apiKey': this.userToken,
            },
            {
                subs_id: data?.subscriptionId,
                num_of_codes: parseInt(data?.activationCode),
                subs_code: data?.subscriptionCode,
                admin_id: this.userId,
            }
        )
            .then(res => {
                if (res.status === 200) {
                    this.setDownloadSuccess(true);
                    this.AlertStore.setAlert('success', 'Success');
                    window.open(res.response.url);
                } else {
                    this.AlertStore.setAlert('error', res?.response?.message);
                }
                this.setDownloadSuccess(false);
            })
            .catch(e => {
                this.AlertStore.setAlert('error', 'Error in downloading code');
                this.setDownloadSuccess(false);
            });
    };

    addSubscription = data => {
        this.setAddSubscriptionSuccess(false);
        HTTPPutRequest(
            `${this.MODULE_URL}add-subscription`,
            {
                'x-stq-apiKey': this.userToken,
            },
            {
                admin_id: this.userId,
                ...data,
            }
        )
            .then(res => {
                if (res.status === 200) {
                    this.setAddSubscriptionSuccess(true);
                    this.setSubscriptionUsersList(res?.response);
                    this.AlertStore.setAlert('success', res?.response?.message);
                } else {
                    this.AlertStore.setAlert('error', res?.response?.message);
                }
                this.setAddSubscriptionSuccess(false);
            })
            .catch(e => {
                this.setAddSubscriptionSuccess(false);
                this.AlertStore.setAlert('error', 'Unable to add a new subscription');
            });
    };

    downloadSubscriptionList = ({ searchVal, subscriptionPartner, subscriptionType, status }) => {
        this.setDownloadSubscriptionListLoading(true);
        HTTPGetRequest(
            `${this.MODULE_URL}download_subscriptions?search_key=${searchVal || ''}&status=${
                status || ''
            }&subs_type=${subscriptionType}&subs_partner=${subscriptionPartner}`,
            {
                'x-stq-apiKey': this.LoginStore.userInfo.token,
            }
        )
            .then(res => {
                this.setDownloadSubscriptionListLoading(false);
                if (res.status === 200) {
                    if (res?.response?.url) {
                        window.open(res.response.url);
                    } else if (!res?.response?.url) {
                        this.AlertStore.setAlert('error', 'No Complaints to download');
                    }
                } else {
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(e => {
                this.setDownloadSubscriptionListLoading(false);
                this.AlertStore.setAlert('error', e);
            });
    };

    downloadSubscriptionUsers = ({ searchVal, status }) => {
        this.setDownloadSubscriptionUsersLoading(true);
        HTTPGetRequest(`${this.MODULE_URL}download_subscription_users?search_key=${searchVal}&status=${status}`, {
            'x-stq-apiKey': this.LoginStore.userInfo.token,
        })
            .then(res => {
                this.setDownloadSubscriptionUsersLoading(false);
                if (res.status === 200) {
                    if (res?.response?.url) {
                        window.open(res.response.url);
                    } else if (!res?.response?.url) {
                        this.AlertStore.setAlert('error', 'No Complaints to download');
                    }
                } else {
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(e => {
                this.setDownloadSubscriptionUsersLoading(false);
                this.AlertStore.setAlert('error', e);
            });
    };
}

export default SubscriptionStore;
