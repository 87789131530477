import { makeAutoObservable } from "mobx";
import ApiConstants from "../constants/ApiConstants";
import {
	getDecryptedItem,
	getItem,
	HTTPRequest,
	removeItem,
	saveEncryptedItem,
	saveItem,
	HTTPGetRequest,
} from "../helpers";
import { log } from "../helpers";

class LoginStore {
	MODULE_URL = `${ApiConstants.BASE_URL}signin/`;
	MODULE_URL_V2 = `${ApiConstants.BASE_URL_V2}signin/`;
	phoneNumber = "";
	password = "";
	AlertStore;
	email = "";
	isLoggedIn = false;
	forgotEmail = "";
	OTP_number = "";
	newPassword = "";
	confirmPassword = "";
	userInfo = {};
	login__loading = false;
	login__error = "";

	forgot__loading = false;
	forgot__error = "";
	forgot_email_sent = false;
	verify__loading = false;
	verify__error = "";

	change__loading = false;
	change__error = "";

	userRoles = [];

	is_reset_loading = false;
	is_resend_success = false;
	resend_msg = "";

	user_name_error = "";
	password_error = "";
	login_loading = false;

	create_password_loading = false;
	create_password_error = "";
	token_error = "";
	token_loading = false;

	constructor() {
		makeAutoObservable(this);
		this.isLoggedIn = getItem("isUserLoggedIn");
		this.AlertStore = this.AlertStore;
		//console.log("USERINF",getDecryptedItem('userInfo'))
		this.userInfo = JSON.parse(
			getDecryptedItem("userInfo") ? getDecryptedItem("userInfo") : "{}"
		);
		this.userRoles = JSON.parse(
			getDecryptedItem("userRoles") ? getDecryptedItem("userRoles") : "{}"
		);
	}

	/*  Login form operations */

	// isValidEmail = () => {
	//     // return this.email.includes('@')
	//     return !isNaN(this.email)
	// }
	setLoading = (val) => {
		this.login__loading = val;
	};
	setError = (error) => {
		this.login__error = error;
	};

	setForgotLoading = (val) => {
		this.forgot__loading = val;
	};

	setForgotError = (val) => {
		this.forgot__error = val;
	};

	setChangeLoading = (val) => {
		this.change__loading = val;
	};

	setChangeError = (val) => {
		this.change__error = val;
	};
	setVerifyLoading = (val) => {
		this.verify__loading = val;
	};

	setVerifyError = (val) => {
		this.verify__error = val;
	};

	setPassword = (value) => {
		this.password = value;
	};
	setUserInfo = (val) => {
		this.userInfo = val;
	};

	setUserRoles = (val) => {
		this.userRoles = val;
	};

	// set resend loading

	setResetLoading = (val) => {
		this.is_reset_loading = val;
	};

	// set resend success

	setResendSuccess = (val) => {
		this.is_resend_success = val;
	};

	// set resend msg
	setResendMsg = (val) => {
		this.resend_msg = val;
	};

	setPhoneNumber = (val) => {
		console.log(val);
		this.phoneNumber = val;
	};

	setNewPassword = (value) => {
		this.newPassword = value;
	};

	setConfirmPassword = (value) => {
		this.confirmPassword = value;
	};

	setUsernameError = (value) => {
		this.user_name_error = value;
	};

	setPasswordError = (value) => {
		this.password_error = value;
	};

	setLoginLoading = (val) => {
		this.login_loading = val;
	};

	setCreatePasswordLoading = (val) => {
		this.create_password_loading = val;
	};

	setCreatePasswordError = (val) => {
		this.create_password_error = val;
	};

	setForgotEmailSent = (value) => {
		this.forgot_email_sent = value;
	};

	setTokenError = (value) => {
		this.token_error = value;
	};

	setTokenLoading = (value) => {
		this.token_loading = value;
	};

	logout = () => {
		this.isLoggedIn = false;
		removeItem("isUserLoggedIn");
		removeItem("userInfo");
		removeItem("userRoles");
	};
	loggedInNow = () => {
		this.isLoggedIn = true;
	};

	/* Login form operations end */

	/* Forgot password operations */

	setForgotEmail = (value) => {
		this.forgotEmail = value;
	};

	generateOTPApi = (history) => {
		this.setLoginLoading(true);
		this.setUsernameError("");
		this.setPasswordError("");

		HTTPRequest(
			`${this.MODULE_URL}generateOTP`,
			{},
			{
				phoneNumber: "91" + this.phoneNumber,
				password: this.password,
			}
		)
			.then((res) => {
				this.setLoginLoading(false);
				if (
					res.status === 200 &&
					res?.response?.status !== 401 &&
					res?.response?.status !== 404
				) {
					this.setPhoneNumber("91" + this.phoneNumber);
					history.push("/auth/verify");
				} else if (res?.response?.status === 401) {
					this.setPasswordError(res.response.message);
				} else if (res?.response?.status === 404) {
					this.setPasswordError(res.response.message);
				} else {
					this.setUsernameError(res.response.message);
				}
			})
			.catch((e) => {
				this.setUsernameError(e);
				this.setLoginLoading(false);
			});
	};

	/* Resend OTP */
	resendOTPApi = () => {
		this.setResetLoading(true);
		this.setResendMsg("");
		this.setResendSuccess(false);
		this.setVerifyError("");
		HTTPRequest(
			`${this.MODULE_URL}resendOTP`,
			{},
			{
				phoneNumber: this.phoneNumber,
			}
		)
			.then((res) => {
				this.setResetLoading(false);

				if (res.status === 200) {
					this.setResendSuccess(true);
					this.setResendMsg(res.response.message);
				} else {
					this.setVerifyError(res.response.message);
				}
			})
			.catch((e) => {
				this.setVerifyError(e);
				this.setForgotLoading(false);
			});
	};

	/* Forgot password operations end  */

	/* OTP Verification operations start */

	setOTP = (value) => {
		this.OTP_number = value;
	};

	verifyOtpApi = () => {
		this.setVerifyError("");
		this.setVerifyLoading(true);
		this.setResendMsg("");

		HTTPRequest(
			`${this.MODULE_URL}verifyOTP`,
			{},
			{
				phoneNumber: this.phoneNumber,
				enteredOTP: this.OTP_number,
			}
		)
			.then((res) => {
				this.setVerifyLoading(false);
				// log("OTP", res)
				if (res.status === 200) {
					// history.push('/auth/change-password')

					let response = res.response;
					let roles = res.response.roles;
					let roleKeys = Object.keys(roles);
					let formattedRoles = {};
					roleKeys.map((key) => {
						let rolesChild = roles[key].child;
						let childObject = {};
						rolesChild.map((child) => {
							let subChildObject = {};
							//childObject[child.name]=child.child
							child.child.map((subChild) => {
								subChildObject[subChild.name] = subChild.value;
								childObject[child.name] = subChildObject;
							});

							formattedRoles[key] = childObject;
						});
					});

					saveEncryptedItem("userInfo", JSON.stringify(response));
					saveEncryptedItem("userRoles", JSON.stringify(formattedRoles));
					saveItem("isUserLoggedIn", true);
					this.setUserInfo(response);
					this.setUserRoles(formattedRoles);
					setTimeout(() => {
						this.loggedInNow();
						window.location.href = "/";
					}, 1);
				} else {
					this.setVerifyError(res.response.message);
				}
			})
			.catch((e) => {
				this.setVerifyError(e);
				this.setVerifyLoading(false);
			});
	};
	/* OTP Verifiction operations end */

	/* Create Password/ Reset Password */
	createPasswordApi = (history, token) => {
		this.setCreatePasswordLoading(true);
		this.setCreatePasswordError("");
		this.setTokenError("");
		HTTPRequest(
			`${ApiConstants.BASE_URL}user/setpassword`,
			{ token },
			{
				password: this.newPassword,
			}
		)
			.then((res) => {
				this.setCreatePasswordLoading(false);
				if (res.status === 200) {
					history.push("/auth");
				} else {
					if (res.status === 400 || res.status === 401 || res.status === 408) {
						this.setTokenError("token_issue");
					} else if (res.status === 405) {
						this.setTokenError("password_already_set");
					} else {
						this.setCreatePasswordError(res.response.message);
					}
				}
			})
			.catch((e) => {
				this.setCreatePasswordError(e);
				this.setCreatePasswordLoading(false);
			});
	};

	/* Check Token */
	checkTokenApi = (token) => {
		this.setTokenError("");
		this.setTokenLoading(true);
		HTTPGetRequest(`${ApiConstants.BASE_URL}user/setpassword?token=${token}`)
			.then((res) => {
				this.setTokenLoading(false);
				if (res.status !== 200) {
					this.setTokenError("token_issue");
				}
			})
			.catch((e) => {
				this.setTokenError(e);
				this.setTokenLoading(false);
			});
	};

	/* Forgot Password */
	forgotPasswordApi = () => {
		this.setForgotLoading(true);
		this.setForgotError("");
		HTTPRequest(
			`${this.MODULE_URL}forgotPass`,
			{},
			{
				email: this.forgotEmail,
			}
		)
			.then((res) => {
				this.setForgotLoading(false);
				if (res.status === 200) {
					this.setForgotEmailSent(true);
				} else {
					this.setForgotError(res.response.message);
				}
			})
			.catch((e) => {
				this.setForgotError(e);
				this.setForgotLoading(false);
			});
	};
}

export default LoginStore;
