import { makeAutoObservable, values } from 'mobx'
import ApiConstants from '../constants/ApiConstants'
import { HTTPGetRequest, HTTPRequest } from '../helpers';


class CouponStore {

    LoginStore;
    AlertStore;
    MODULE_URL = `${ApiConstants.BASE_URL}coupon/`;
    is_get_coupon_loading = false;
    is_get_coupon_success = false;
    is_add_coupon_loading = false;
    is_add_coupon_success = false;
    coupon_data = {}
    is_update_coupon_loading = false;
    is_update_coupon_success = false;



    constructor(stores) {
        makeAutoObservable(this);
        this.LoginStore = stores.LoginStore;
        this.userToken = this.LoginStore.userInfo.token;
        this.userId = this.LoginStore.userInfo.id;
        this.company = this.LoginStore.userInfo.companyId;
        this.AlertStore = stores.AlertStore;
    }

    setGetCouponLoading = (value) => {
        this.is_get_coupon_loading = value;
    }

    setGetCouponSuccess = (value) => {
        this.is_get_coupon_success = value
    }

    setGetCouponData = (data) => {
        this.coupon_data = data.response
    }

    setAddCouponLoading = (value) => {
        this.is_add_coupon_loading = value
    }

    setAddCouponSuccess = (value) => {
        this.is_add_coupon_success = value
    }

    setUpdateCouponLoading = (value) => {
        this.is_update_coupon_loading = value
    }

    setUpdateCouponSuccess = (value) => {
        this.is_update_coupon_success = value
    }

    getAllCoupons = (data, loadable = true) => {
        this.setGetCouponLoading(loadable);
        this.setUpdateCouponSuccess(false)
        HTTPGetRequest(`${this.MODULE_URL}?id=${this.userId}&page=${data.page}&isActive=${data.isActive}&category=${data.category}`,
            {
                'x-stq-apiKey': this.userToken
            }).then((res) => {
                this.setGetCouponLoading(false)
                if (res.status === 200) {
                    this.setGetCouponData(res);
                }
                else {
                    this.AlertStore.setAlert('error', res.response.message);
                }
            }).catch(e => {
                this.AlertStore.setAlert('error', e);
                this.setGetCouponLoading(false)
            })


    }


    addCouponApi = (data) => {
        this.setAddCouponLoading(true);
        HTTPRequest(`${this.MODULE_URL}`,
            {
                'x-stq-apiKey': this.userToken
            }, {
            adminId: this.userId,
            ...data,

        }).then((res) => {
            this.setAddCouponLoading(false)

            if (res.status === 200) {
                this.setAddCouponSuccess(true)
                this.AlertStore.setAlert('success', res.response.message)
            }
            else {
                this.setAddCouponSuccess(false)
                this.AlertStore.setAlert('error', res.response.message);
            }
        }).catch(e => {
            this.AlertStore.setAlert('error', e);
            this.setGetCouponLoading(false)
            this.setAddCouponSuccess(false)
        })


    }

    updateCouponApi = (id, value) => {
        this.setUpdateCouponLoading(true);
        HTTPRequest(`${this.MODULE_URL}modify-status`,
            {
                'x-stq-apiKey': this.userToken
            }, {

            couponId: id,
            isActive: value

        }).then((res) => {
            this.setUpdateCouponLoading(false)

            if (res.status === 200) {
                this.setUpdateCouponSuccess(true)
                this.AlertStore.setAlert('success', res.response.message)
            }
            else {
                this.setAddCouponSuccess(false)
                this.AlertStore.setAlert('error', res.response.message);
            }
        }).catch(e => {
            this.AlertStore.setAlert('error', e);
            this.setUpdateCouponLoading(false)
            this.setUpdateCouponSuccess(false)
        })


    }

}

export default CouponStore;