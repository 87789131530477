import { makeAutoObservable } from 'mobx'
import ApiConstants from '../constants/ApiConstants';
import { HTTPRequest, HTTPGetRequest } from '../helpers/ServerHelper';

class PaymentStore {

    MODULE_URL = `${ApiConstants.BASE_URL}payment/`;
    LoginStore;
    AlertStore;
    userToken;
    userId;
    is_razorPay_loading = false;
    is_razorPay_success = false;
    is_refund_loading = false;
    is_refund_success = false;
    is_trail_loading = false;
    is_trail_success = false;
    is_trail_error = "";
    trail_values = []

    constructor(stores) {
        makeAutoObservable(this)
        this.LoginStore = stores.LoginStore;
        this.AlertStore = stores.AlertStore;
        this.userToken = this.LoginStore.userInfo.token;
        this.userId = this.LoginStore.userInfo.id;
    }

    // set loading of razorpay
    setRazorPayLoading = (value) => {
        this.is_razorPay_loading = value
    }

    // set loading of razorpay
    setRazorPaySuccess = (value) => {
        this.is_razorPay_success = value
    }
    // set loading of refund
    setRefundLoading = (value) => {
        this.is_refund_loading = value
    }

    // set loading of refund
    setRefundSuccess = (value) => {
        this.is_refund_success = value
    }
    // set loading of trail
    setTrailLoading = (value) => {

        this.is_trail_loading = value
    }

    // set success of trail
    setTrailSuccess = (value) => {
        this.is_trail_success = value
    }
    // set error msg of trail
    setTrailMsg = (value) => {
        this.is_trail_error = value
    }

    // set trail values 
    setTrailValues = (values) => {
        this.trail_values = values
    }

    resetRazorPaySuccess = () => {
        this.setRazorPaySuccess(false);
    }

    // payment through razorpay
    paymentRazorPay = (data) => {
        this.setRazorPayLoading(true);
        this.setRazorPaySuccess(false);

        HTTPRequest(`${this.MODULE_URL}razorpay`, {
            'x-stq-apiKey': this.userToken
        },
            {
                razorpay_payment_id: data.payment_id,
                company_id: data.company_id,
                isCompany: data.isCompany
            }).then((res) => {
                this.setRazorPayLoading(false);
                if (res.status === 200) {
                    this.setRazorPaySuccess(true)
                    this.AlertStore.setAlert('success', res.response.message)
                }
                else {
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(err => {
                this.setRazorPayLoading(false);
                this.AlertStore.setAlert('error', err)
            })
    }

    // refund through razorpay
    refundRazorPay = (data) => {
        // console.log("data", data)
        this.setRefundLoading(true);
        this.setRefundSuccess(false);

        HTTPRequest(`${this.MODULE_URL}razorpay-refund`, {
            'x-stq-apiKey': this.userToken
        },
            {
                razorpay_payment_id: data.payment_id,
                company_id: data.company_id,
                user_id: data.user_id,
                isCompany: data.isCompany,
                admin_id: this.userId,
                description: data.description,
                amount: parseInt(data.amount * 100)

            }).then((res) => {
                this.setRefundLoading(false);
                if (res.status === 200) {
                    this.setRefundSuccess(true)
                    this.AlertStore.setAlert('success', res.response.message)
                }
                else {
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(err => {
                this.setRefundLoading(false);
                this.AlertStore.setAlert('error', err)
            })
    }

    // refund trails
    refundTrails = (id) => {
        // console.log("data", data)
        this.setTrailLoading(true);
        this.setTrailSuccess(false);

        HTTPGetRequest(`${this.MODULE_URL}refund-list?payment_id=${id}`, {
            'x-stq-apiKey': this.userToken
        }, {}).then((res) => {
            this.setTrailLoading(false);
            if (res.status === 200) {
                this.setTrailSuccess(true)
                this.setTrailMsg(res.response.message);
                this.setTrailValues(res.response.refunds)
                // this.AlertStore.setAlert('success', res.response.message)
            }
            else {
                this.this.setTrailMsg(res.response.message)
                this.AlertStore.setAlert('error', res.response.message);
            }
        })
            .catch(err => {
                this.setTrailSuccess(false);
                this.AlertStore.setAlert('error', err)
            })
    }


}

export default PaymentStore;