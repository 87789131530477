import { useState, useEffect } from 'react';

export function ucfirst(string) {

	return string.split(',').map(con => con.charAt(0).toUpperCase() + con.slice(1)).join();
}

export function log(TAG, ...VAL) {
	//   return console.log(TAG, VAL.join())
	return null
}

export function saveItem(key, value) {
	localStorage.setItem(key, value)
}

export function getItem(key) {
	return localStorage.getItem(key) != null ? localStorage.getItem(key) : ''
}

export function saveEncryptedItem(key, value) {
	let encodedString = btoa(value)
	localStorage.setItem(key, encodedString)
}

export function getDecryptedItem(key) {

	let decodedString = atob(localStorage.getItem(key))
	return localStorage.getItem(key) ? decodedString : null
}

export function removeItem(key) {
	localStorage.removeItem(key)
}

export function formValidation(values) {
	console.log(values)
	// if(values.toString().includes('required')){
	// 	console.log('required')
	// }
}

export function formatPhoneNumber(value) {
	if (value !== null) {
		let stringValue = value.toString()
		let code = stringValue.substring(0, 2)
		let number = stringValue.substring(2)
		return `+${code}-${number}`
	}
	else {
		return value
	}

}

export function RazorPay(key, amount, logo__statiq, handlerSuccess, notes = {}, prefill, failureFunc = null, resetFunc = null) {
	const options = {
		key: key,
		amount: (parseInt(amount) * 1) * 100,
		currency: 'INR',
		name: "Statiq",
		description: 'Payment Through Razorpay.',
		image: logo__statiq,
		notes: notes,
		handler: async () =>
			setTimeout(() => {
				handlerSuccess()

			}, 1000)
		,
		prefill: prefill,

		theme: {
			color: "#203594",
		},
		modal: {
			ondismiss: function () {
				document.body.style.overflow = 'auto';
				resetFunc()
			}
		}
	};

	const paymentObject = new window.Razorpay(options);
	paymentObject.on('payment.failed', () =>
		failureFunc()
	);
	paymentObject.open();

}
export const useDebounce = (value, milliSeconds) => {
	const [debouncedValue, setDebouncedValue] = useState(value);
   
	useEffect(() => {
	  const handler = setTimeout(() => {
		setDebouncedValue(value);
	  }, milliSeconds);
   
	  return () => {
		clearTimeout(handler);
	  };
	}, [value, milliSeconds]);
   
	return debouncedValue;
};
