import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { inject, observer } from 'mobx-react';

const Toastr = inject('AlertStore')(observer((props) => {

    const { alert_type, alert_msg, alert_loading } = props.AlertStore
    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    const renderToastr = () => (
        <div>

            {
                <Snackbar
                    open={open}
                    onClose={handleClose}
                    autoHideDuration={1200}
                    style={{ zIndex: 9999 }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    key='toastr' >
                    {
                        alert_type === 'success' ?
                            <SnackbarContent style={{
                                backgroundColor: 'green',
                            }}
                                message={alert_msg}
                            />
                            :
                            <SnackbarContent style={{
                                backgroundColor: 'red',
                            }}
                                message={alert_msg}
                            />


                    }

                </Snackbar>

            }

        </div>
    )

    useEffect(() => {
        if (alert_loading) {
            setOpen(true)
        }

    }, [alert_loading])

    return (
        renderToastr()
    )

}))

export default Toastr
