import { FormatListBulleted } from "@material-ui/icons";
import { makeAutoObservable } from "mobx";
import ApiConstants from "../constants/ApiConstants";
import { HTTPRequest, HTTPGetRequest, HTTPDeleteRequest } from "../helpers";

class UserStore {
	LoginStore;
	AlertStore;
	MODULE_URL = `${ApiConstants.BASE_URL}user/`;
	isLoadingUser = false;
	is_add_admin_loading = false;
	is_add_admin_success = false;
	is_add_admin_msg = undefined;
	is_add_ev_loading = false;
	is_add_ev_success = false;

	isOverviewLoadingUser = false;
	isGraphLoadingUser = false;
	isLoadingRfid = false;
	success = false;
	isRfidSuccess = false;
	is_user_active_loading = false;
	login__error;
	evOwnersList = {};
	administratorData = {};
	companiesData = {};
	userGraphData = {};
	userOverviewData = {};
	editSuccess = false;
	is_ev_error = undefined;
	is_admin_error = undefined;
	is_comp_error = undefined;
	is_add_user_vehicle_success = false;
	is_company_loading = false;
	is_edit_admin_loading = false;
	is_edit_admin_success = false;
	is_edit_ev_loading = false;
	is_search_EvOwners_Loading = false;
	is_search_Evowners_success = false;
	searchEvOwnersData = {};
	is_uservehicle_delete_loading = false;
	is_uservehicle_delete_success = false;
	is_wallet_history_loading = false;
	is_wallet_history_success = false;
	walletHistoryData = {};
	is_company_wallet_history_loading = false;
	is_company_wallet_history_success = false;
	companyWalletHistoryData = {
		availBal: 0,
		wallet: [],
		page: "",
		total_pages: 0,
	};
	is_add_credit_loading = false;
	is_add_credit_success = false;
	is_company_wallet_history_add_more_loading = false;
	unique_customer_data = {};
	is_unique_customer_loading = false;
	is_vehicle_data_loading = false;
	vehicleData = {};
	company_data_loading = false;
	companyData = {};
	categoryList = [];
	subscriptionList = []
	downloadEvListLoading = false

	constructor(stores) {
		makeAutoObservable(this);
		this.LoginStore = stores.LoginStore;
		this.AlertStore = stores.AlertStore;
		this.userToken = this.LoginStore.userInfo.token;
		this.userId = this.LoginStore.userInfo.id;
		this.company = this.LoginStore.userInfo.companyId;
	}

	setEditSuccess = (val) => {
		this.editSuccess = val;
	};

	// Set loading
	setLoading(value) {
		this.isLoadingUser = value;
	}

	// Setrfid loading
	setRfidLoading(value) {
		this.isLoadingRfid = value;
	}

	// set rfid success
	setRfidSuccess(value) {
		// console.log(value)
		this.isRfidSuccess = value;
	}

	setGraphLoading(value) {
		this.isGraphLoadingUser = value;
	}

	setUserOverviewLoading(value) {
		this.isOverviewLoadingUser = value;
	}

	// set add admin helpers
	setAddAdminLoading(value) {
		this.is_add_admin_loading = value;
	}
	setAddAdminsuccess(value) {
		this.is_add_admin_success = value;
	}

	// set admin  edit
	seteditAdminLoading = (value) => {
		this.is_edit_admin_loading = value;
	};

	// set admin edit success

	setEditAdminSuccess = (value) => {
		this.is_edit_admin_success = value;
	};

	// set add ev helpers
	setAddEvLoading(value) {
		this.is_add_ev_loading = value;
	}
	setAddEvSuccess(value) {
		this.is_add_ev_success = value;
	}

	// set user active loading
	setUserLoadingStatus(value) {
		this.is_user_active_loading = value;
	}

	// Setting Helpers
	setError = (error) => {
		this.login__error = error;
	};
	setSuccess = (val) => {
		this.success = val;
	};

	// set add vehicle success
	setAddVehicleSuccess = (value) => {
		this.is_add_user_vehicle_success = value;
	};

	// set ev owners Data

	setEvOwnersData = (data) => {
		this.evOwnersList = data.response;
	};
	// set ev errors
	setEvError = (data) => {
		this.is_ev_error = data;
	};

	setEditEvLoading(value) {
		this.is_edit_ev_loading = value;
	}

	// Set graph data
	setGraphData = (data) => {
		this.userGraphData = data.response;
	};

	//set admininstrator

	setAdminstartorData = (data) => {
		this.administratorData = data.response;
	};
	//set companies data

	setCompaniesData = (data) => {
		this.companiesData = data.response;
	};

	setUserOverviewData = (data) => {
		this.userOverviewData = data.response;
	};
	// set admin error
	setAdminError = (data) => {
		this.is_admin_error = data;
	};
	// set comp error
	setCompanyError = (data) => {
		this.is_comp_error = data;
	};
	// set Company loading
	setCompanyLoading = (value) => {
		this.is_company_loading = value;
	};

	// search Ev owners
	setSearchEvOwnersLoading(value) {
		this.is_search_EvOwners_Loading = value;
	}
	setSearchEvOwnersSuccess(value) {
		this.is_search_Evowners_success = value;
	}
	searchEvownersList(data) {
		this.searchEvOwnersData = data;
	}

	// set vehicle deletion
	setDeleteUserVehicleLoading(value) {
		this.is_uservehicle_delete_loading = value;
	}

	setDeleteUserVehicleSuccess(value) {
		this.is_uservehicle_delete_success = value;
	}

	// set wallet history
	setWalletHistoryLoading(value) {
		this.is_wallet_history_loading = value;
	}

	setWalletHistorySuccess(value) {
		this.is_wallet_history_success = value;
	}

	setWalletHistoryData(data) {
		this.walletHistoryData = data;
	}

	setCategoryList(data){
		this.categoryList = data
	}

	setSubscriptionList(data){
		this.subscriptionList = data
	}

	// set company wallet history
	setCompanyWalletHistoryLoading(value) {
		this.is_company_wallet_history_loading = value;
	}
	setCompanyWalletHistoryAddMoreLoading(value) {
		this.is_company_wallet_history_add_more_loading = value;
	}
	setCompanyWalletHistorySuccess(value) {
		this.is_company_wallet_history_success = value;
	}

	setCompanyWalletHistoryData(res, currentPage) {
		this.companyWalletHistoryData = {
			availBal: res?.data?.availBal,
			wallet: res?.data?.wallet,
			page: currentPage,
			total_pages:
				currentPage === 1
					? res?.data?.total_pages
					: this.companyWalletHistoryData?.total_pages,
		};
	}

	setUniqueCustomerLoading(value) {
		this.is_unique_customer_loading = value;
	}

	setUniqueCustomerData(data) {
		this.unique_customer_data = data.response;
	}

	// set add credit loading
	setAddCreditLoading = (value) => {
		this.is_add_credit_loading = value;
	};

	// set add credit success
	setAddCreditSuccess = (value) => {
		this.is_add_credit_success = value;
	};

	setVehicleLoading = (value) => {
		this.is_vehicle_data_loading = value;
	};

	setVehicleData = (data) => {
		this.vehicleData = data.response;
	};

	setCompanyDataLoading = (value) => {
		this.company_data_loading = value;
	};

	setCompanyData = (data) => {
		this.companyData = data.response;
	};

	setDownloadEvListLoading = (val)=> {
		this.downloadEvListLoading = val
	}

	// get user overview

	getUserOverviewData = () => {
		// log("STORE",JSON.stringify(this.AlertStore) )

		this.setUserOverviewLoading(true);
		HTTPRequest(
			`${this.MODULE_URL}overview`,
			{
				"x-stq-apiKey": this.userToken,
			},
			{
				id: this.userId,
			}
		)
			.then((res) => {
				this.setUserOverviewLoading(false);
				if (res.status === 200) {
					this.setUserOverviewData(res);
				}
			})
			.catch((e) => {
				this.setError(e);
				this.setLoading(false);
			});
	};

	//get user graph data
	getGraphData = (start, end) => {
		this.setGraphLoading(true);
		HTTPRequest(
			`${this.MODULE_URL}usersByDates`,
			{
				"x-stq-apiKey": this.userToken,
			},
			{
				id: this.userId,
				startDate: start,
				endDate: end,
			}
		)
			.then((res) => {
				this.setGraphLoading(false);
				if (res.status === 200) {
					this.setGraphData(res);
				}
			})
			.catch((e) => {
				this.setError(e);
				this.setLoading(false);
			});
	};

	// get user details
	getEvOwnersDetails = (loadable = true, apiRequest) => {
		this.setAddEvLoading(false);
		this.setAddEvSuccess(false);
		this.setLoading(loadable);
		this.setEvError(undefined);
		this.setEditSuccess(false);
		this.setAddVehicleSuccess(false);
		this.setRfidSuccess(false);
		this.seteditAdminLoading(false);
		this.setEditAdminSuccess(false);
		this.setWalletHistorySuccess(false);
		this.setWalletHistoryLoading(false);
		this.setAddCreditSuccess(false);
		HTTPRequest(
			`${this.MODULE_URL}listEVOwners`,
			{
				"x-stq-apiKey": this.userToken,
			},
			{
				id: this.userId,
				...apiRequest,
			}
		)
			.then((res) => {
				this.setLoading(false);
				if (res.status === 200) {
					this.setEvOwnersData(res);
				} else {
					this.setEvError(res.response.message);
				}
			})
			.catch((e) => {
				this.setError(e);
				this.setLoading(false);
			});
	};

	// add ev owner
	addEvOwner = (data, props) => {
		this.setAddEvLoading(true);
		this.setAddEvSuccess(false);

		HTTPRequest(
			`${this.MODULE_URL}addEVOwner`,
			{
				"x-stq-apiKey": this.userToken,
			},
			{
				fullName: data.full_name,
				email: data.email,
				password: data.password,
				phone: data.phone_num,
				vehicleId: data.vehicle ? data.vehicle : 0,
				vehicleNumber: data.veh_number,
				companyId: data.companyId ? data.companyId : this.company,
				credits: data.credits,
				admin_id: this.userId,
			}
		)
			.then((res) => {
				this.setAddEvLoading(false);

				if (res.status === 200) {
					this.setAddEvSuccess(true);
					this.AlertStore.setAlert("success", res.response.message);
					props.closeAdd();
				} else {
					this.setAddEvSuccess(false);
					this.AlertStore.setAlert("error", res.response.message);
				}
			})
			.catch((e) => {
				this.setError(e);
				this.setAddEvLoading(false);
				this.AlertStore.setAlert("error", e);
			});
	};

	// edit ev owner
	editEvOwner = (data, props) => {
		this.setEditEvLoading(true);
		this.setEditSuccess(false);
		HTTPRequest(
			`${this.MODULE_URL}edit/EVOwner`,
			{
				"x-stq-apiKey": this.userToken,
			},
			{
				adminId: this.userId,
				fullName: data.full_name,
				password: data.password ? data.password : "",
				phone: data.phone_num,
				email: data.email,
				userId: data.userId,
				credits: data.credits,
				admin_id: this.userId,
			}
		)
			.then((res) => {
				this.setEditEvLoading(false);
				if (res.status === 200) {
					this.setEditSuccess(true);
					this.AlertStore.setAlert("success", res.response.message);
					props.closeModal(false);
				} else {
					this.AlertStore.setAlert("error", res.response.message);
				}
			})
			.catch((e) => {
				this.setError(e);
				this.setEditEvLoading(false);
			});
	};

	    //Download EV owners
		downloadEVOwners = ({name, userStatus, companyId, page}) => {
			this.setDownloadEvListLoading(true)
			HTTPGetRequest(
				`${this.MODULE_URL}download_users?name=${name}&userStatus=${userStatus}&companyId=${companyId}&id=${this.userId}&page=${page}`,
				{
					'x-stq-apiKey': this.LoginStore.userInfo.token,
				},
			)
				.then(res => {
					this.setDownloadEvListLoading(false)
					if (res.status === 200) {
						if (res?.response?.url) {
							window.open(res.response.url);
						} else if (!res?.response?.url) {
							this.AlertStore.setAlert('error', 'No Complaints to download');
						}
					} else {
						this.AlertStore.setAlert('error', res.response.message);
					}
				})
				.catch(e => {
					this.setDownloadEvListLoading(false)
					this.AlertStore.setAlert('error', e);
				});
		};

	// active in active users

	activeInactiveuser = (status, id) => {
		this.setUserLoadingStatus(true);
		this.setEditSuccess(false);
		HTTPRequest(
			`${this.MODULE_URL}modify/evOwnerStatus`,
			{
				"x-stq-apiKey": this.userToken,
			},
			{
				action: status,
				userId: id,
			}
		)
			.then((res) => {
				this.setUserLoadingStatus(false);
				if (res.status === 200) {
					this.setEditSuccess(true);
					this.AlertStore.setAlert("success", res.response.message);
				} else {
					this.AlertStore.setAlert("error", res.response.message);
				}
			})
			.catch((e) => {
				this.setUserLoadingStatus(false);
				this.setEditSuccess(false);
			});
	};

	// add ev owner vehicle
	addUserVehicle = (data) => {
		this.setAddVehicleSuccess(false);
		HTTPRequest(
			`${this.MODULE_URL}addUserVehicle`,
			{
				"x-stq-apiKey": this.userToken,
			},
			{
				userId: data.phone,
				vehicleId: data.vehicle_id,
				vehicleNumber: data.vehicle_num,
			}
		)
			.then((res) => {
				this.setLoading(false);
				if (res.status === 200) {
					this.AlertStore.setAlert("success", res.response.message);
					this.setAddVehicleSuccess(true);
				} else {
					this.AlertStore.setAlert("error", res.response.message);
				}
			})
			.catch((e) => {
				this.setError(e);
				this.setAddVehicleSuccess(false);
			});
	};

	// get administrator
	getAdministrator = (loadable = true, page, search) => {
		this.setLoading(loadable);
		this.setAdminError(undefined);
		this.setAddAdminLoading(false);
		this.setAddAdminsuccess(false);
		this.seteditAdminLoading(false);
		this.setEditAdminSuccess(false);
		HTTPRequest(
			`${this.MODULE_URL}listAdminUsers`,
			{
				"x-stq-apiKey": this.userToken,
			},
			{
				id: this.userId,
				page: page,
				search: search,
			}
		)
			.then((res) => {
				this.setLoading(false);
				if (res.status === 200) {
					this.setAdminstartorData(res);
				} else {
					this.setAdminError(res.response.message);
				}
			})
			.catch((e) => {
				this.setError(e);
				this.setLoading(false);
			});
	};

	// add admin
	addAdmin = (data) => {
		this.setAddAdminLoading(true);
		this.setAddAdminsuccess(false);
		HTTPRequest(
			`${this.MODULE_URL}addAdmin`,
			{
				"x-stq-apiKey": this.userToken,
			},
			{
				admin_id: this.userId,
				fullName: data.full_name,
				email: data.email,
				password: data.password,
				phone: "91" + data.phone_num,
				companyId: data.companyId,
				roleId: data.roles,
			}
		)
			.then((res) => {
				this.setAddAdminLoading(false);
				if (res.status === 200) {
					this.setAddAdminsuccess(true);
					this.AlertStore.setAlert("success", res.response.message);
				} else {
					this.setAddAdminsuccess(false);
					this.AlertStore.setAlert("error", res.response.message);
				}
			})
			.catch((e) => {
				this.setError(e);
				this.setAddAdminLoading(false);
				this.AlertStore.setAlert("error", e);
			});
	};
	// edit admin
	editAdmin = (data) => {
		this.seteditAdminLoading(true);
		this.setEditAdminSuccess(false);
		HTTPRequest(
			`${this.MODULE_URL}editAdmin`,
			{
				"x-stq-apiKey": this.userToken,
			},
			{
				fullName: data.full_name,
				password: data.password,
				id: data.id,
			}
		)
			.then((res) => {
				this.seteditAdminLoading(false);
				if (res.status === 200) {
					this.setEditAdminSuccess(true);
					this.AlertStore.setAlert("success", res.response.message);
				} else {
					this.setEditAdminSuccess(false);
					this.AlertStore.setAlert("error", res.response.message);
				}
			})
			.catch((e) => {
				this.setError(e);
				this.seteditAdminLoading(false);
			});
	};

	// get companies
	getCompaniesData = (loadable = true) => {
		this.setCompanyLoading(loadable);
		this.setCompanyError(undefined);
		this.setAddCreditSuccess(false);
		HTTPRequest(
			`${this.MODULE_URL}companyData`,
			{
				"x-stq-apiKey": this.userToken,
			},
			{
				id: this.userId,
			}
		)
			.then((res) => {
				this.setCompanyLoading(false);
				if (res.status === 200) {
					this.setCompaniesData(res);
				} else {
					this.setCompanyError(res.response.message);
				}
			})
			.catch((e) => {
				this.setError(e);
				this.setCompantLoading(false);
			});
	};
	// add companies
	addCompaniesData = () => {
		this.setLoading(true);
		HTTPRequest(
			`${this.MODULE_URL}companyData`,
			{
				"x-stq-apiKey": this.userToken,
			},
			{}
		)
			.then((res) => {
				this.setLoading(false);
				if (res.status === 200) {
					this.setSuccess(true);
					this.AlertStore.setAlert("success", res.response.message);
					this.setCompaniesData(res);
				} else {
					this.AlertStore.setAlert("error", res.response.message);
				}
			})
			.catch((e) => {
				this.setError(e);
				this.setLoading(false);
			});
	};

	// add rfid
	addRfid = (data) => {
		this.setRfidLoading(true);
		this.setRfidSuccess(false);
		HTTPRequest(
			`${this.MODULE_URL}add/userRfid`,
			{
				"x-stq-apiKey": this.userToken,
			},
			{
				...data,
			}
		)
			.then((res) => {
				this.setRfidLoading(false);
				if (res.status === 200) {
					this.setRfidSuccess(true);
					this.AlertStore.setAlert("success", res.response.message);
				} else {
					this.AlertStore.setAlert("error", res.response.message);
				}
			})
			.catch((e) => {
				this.setError(e);
				this.setRfidLoading(false);
			});
	};
	// delete rfid
	deleteRfid = (data) => {
		this.setRfidLoading(true);
		this.setRfidSuccess(false);
		HTTPRequest(
			`${this.MODULE_URL}delete/userRfid`,
			{
				"x-stq-apiKey": this.userToken,
			},
			{
				...data,
			}
		)
			.then((res) => {
				this.setRfidLoading(false);
				if (res.status === 200) {
					this.setRfidSuccess(true);
					this.AlertStore.setAlert("success", res.response.message);
				} else {
					this.AlertStore.setAlert("error", res.response.message);
				}
			})
			.catch((e) => {
				this.setError(e);
				this.setRfidLoading(false);
			});
	};
	// delete user vehicle
	deleteUserVehicle = (data) => {
		this.setDeleteUserVehicleLoading(true);
		this.setDeleteUserVehicleSuccess(false);
		HTTPDeleteRequest(
			`${this.MODULE_URL}deleteUserVehicle/${data.userId}/${data.vehicleName}`,
			{
				"x-stq-apiKey": this.userToken,
			}
		)
			.then((res) => {
				this.setDeleteUserVehicleLoading(false);
				if (res.status === 200) {
					this.setDeleteUserVehicleSuccess(true);
					this.AlertStore.setAlert("success", res.response.message);
				} else {
					this.AlertStore.setAlert("error", res.response.message);
				}
			})
			.catch((e) => {
				this.AlertStore.setAlert("error", e);
				this.setDeleteUserVehicleLoading(false);
			});
	};

	// search ev owners
	searchEvOwners = (data) => {
		this.setSearchEvOwnersLoading(true);
		this.setSearchEvOwnersSuccess(false);
		HTTPGetRequest(
			`${this.MODULE_URL}search/evOwner?search=${data.search}&page=${data.page}&id=${this.userId}`,
			{
				"x-stq-apiKey": this.userToken,
			},
			{}
		)
			.then((res) => {
				this.setSearchEvOwnersLoading(false);
				if (res.status === 200) {
					this.setSearchEvOwnersSuccess(true);
					this.searchEvownersList(res.response);
				} else {
					this.setSearchEvOwnersSuccess(false);
					this.AlertStore.setAlert("error", res.response.message);
				}
			})
			.catch((e) => {
				this.setSearchEvOwnersLoading(false);
			});
	};
	// get wallet data
	walletHistory = (id) => {
		this.setWalletHistoryLoading(true);
		this.setWalletHistorySuccess(false);

		HTTPGetRequest(
			`${this.MODULE_URL}user-wallet?userId=${id}`,
			{
				"x-stq-apiKey": this.userToken,
			},
			{}
		)
			.then((res) => {
				this.setWalletHistoryLoading(false);
				if (res.status === 200) {
					this.setWalletHistorySuccess(true);
					this.setWalletHistoryData(res.response);
				} else {
					this.setWalletHistorySuccess(false);
					this.AlertStore.setAlert("error", res.response.message);
				}
			})
			.catch((e) => {
				this.setWalletHistoryLoading(false);
			});
	};

	// get company wallet
	comapnyWalletHistory = ({ id, page = 1 }) => {
		this.setCompanyWalletHistorySuccess(false);
		page === 1
			? this.setCompanyWalletHistoryLoading(true)
			: this.setCompanyWalletHistoryAddMoreLoading(true);

		HTTPGetRequest(
			`${this.MODULE_URL}company-wallet?companyId=${
				id ? id : this.company
			}&page=${page}`,
			{
				"x-stq-apiKey": this.userToken,
			},
			{}
		)
			.then((res) => {
				page === 1
					? this.setCompanyWalletHistoryLoading(false)
					: this.setCompanyWalletHistoryAddMoreLoading(false);
				if (res.status === 200) {
					this.setCompanyWalletHistorySuccess(true);
					this.setCompanyWalletHistoryData(res.response, page);
				} else {
					this.setCompanyWalletHistorySuccess(false);
					this.AlertStore.setAlert("error", res.response.message);
				}
			})
			.catch((e) => {
				this.setCompanyWalletHistoryLoading(false);
			});
	};

	// add credit
	addCredits = (data) => {
		this.setAddCreditLoading(true);
		this.setAddCreditSuccess(false);
		HTTPRequest(
			`${this.MODULE_URL}add-credit`,
			{
				"x-stq-apiKey": this.userToken,
			},
			{
				adminId: this.userId,
				...data,
			}
		)
			.then((res) => {
				this.setAddCreditLoading(false);
				if (res.status === 200) {
					this.setAddCreditSuccess(true);
					this.AlertStore.setAlert("success", res.response.message);
				} else {
					this.AlertStore.setAlert("error", res.response.message);
				}
			})
			.catch((e) => {
				this.setError(e);
				this.setAddCreditLoading(false);
			});
	};

	//get unique customer graph data
	getUniqueCustomerData = (start, end) => {
		this.setUniqueCustomerLoading(true);
		HTTPRequest(
			`${this.MODULE_URL}uniqUsersByDates`,
			{
				"x-stq-apiKey": this.userToken,
			},
			{
				id: this.userId,
				startDate: start,
				endDate: end,
			}
		)
			.then((res) => {
				this.setUniqueCustomerLoading(false);
				if (res.status === 200) {
					this.setUniqueCustomerData(res);
				}
			})
			.catch((e) => {
				this.setError(e);
				this.setUniqueCustomerLoading(false);
			});
	};

	getVehicleData = ({ loadable = true, searchKey = "" }) => {
		this.setVehicleLoading(loadable);
		HTTPGetRequest(`${this.MODULE_URL}vehicleData?search=${searchKey}`, {
			"x-stq-apiKey": this.userToken,
		})
			.then((res) => {
				this.setVehicleLoading(false);
				if (res.status === 200) {
					this.setVehicleData(res);
				}
			})
			.catch((e) => {
				this.setError(e);
				this.setVehicleLoading(false);
			});
	};

	getCompanyData = ({ loadable = true, searchKey = "" }) => {
		this.setCompanyDataLoading(loadable);
		HTTPGetRequest(
			`${this.MODULE_URL}getCompanyList?id=${this.userId}&search=${searchKey}`,
			{
				"x-stq-apiKey": this.userToken,
			}
		)
			.then((res) => {
				this.setCompanyDataLoading(false);
				if (res.status === 200) {
					this.setCompanyData(res);
				} else {
					this.AlertStore.setAlert("error", res.response.message);
				}
			})
			.catch((e) => {
				this.setError(e);
				this.setCompanyDataLoading(false);
			});
	};

		//get categoryList
		getCategoryList = () => {
			HTTPGetRequest(
				`${this.MODULE_URL}listCategories`,
				{
					"x-stq-apiKey": this.userToken,
				},
			)
				.then((res) => {
					if (res.status === 200) {
						this.setCategoryList(res.response.categories)
					}
				})
				.catch((e) => {
					this.setError(e);
					this.setCategoryList([])
				});
		};

				//get SubscriptionList
				getSubscriptionList = () => {
					HTTPGetRequest(
						`${this.MODULE_URL}listSubscriptions`,
						{
							"x-stq-apiKey": this.userToken,
						},
					)
						.then((res) => {
							if (res.status === 200) {
								this.setSubscriptionList(res.response['subscription list'])
							}
						})
						.catch((e) => {
							this.setError(e);
							this.setSubscriptionList([])
						});
				};
}

export default UserStore;
