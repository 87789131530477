import { makeAutoObservable } from "mobx"
import ApiConstants from "../constants/ApiConstants";
import { HTTPRequest, HTTPGetRequest } from "../helpers";


class AllComplaintsStore {
    LoginStore;
    AlertStore;
    CommonStore;
    loading = false
    addloading = false
    success = false
    complaintGraphLoading = false
    complaintReportLoading = false
    error = undefined
    response = {}
    complaintGraphData = {}
    complaintReportData = {}
    Chargersloading = false;
    Chargerssuccess = false;
    chargersData = {};
    is_get_complaint_type_loading = false;
    is_get_complaint_type_success = false;
    getComplaintTypeData = []
    is_get_complaint_status_loading = false;
    is_get_complaint_status_success = false;
    complaintStatusData = [];
    is_get_comment_loading = false;
    is_get_comment_success = false;
    commentsData = [];
    is_add_comment_loading = false;
    is_add_comment_success = false;
    complaint_error = undefined;
    is_complaint_loading = false;
    is_priority_loading = false;
    is_priority_success = false;
    is_priority_data = [];

    constructor(stores) {
        makeAutoObservable(this)
        this.LoginStore = stores.LoginStore
        this.AlertStore = stores.AlertStore
    }
    setLoading(val) {
        this.loading = val
    }

    setComplaintsGraphLoading(val) {
        this.complaintGraphLoading = val
    }
    setComplaintsReportLoading(val) {
        this.complaintReportLoading = val
    }
    setAddLoading(val) {
        this.addloading = val
    }
    setError = (err) => {
        this.error = err
    }
    setResponse = (val) => {
        this.response = val
    }

    setComplaintGraphData = (data) => {
        this.complaintGraphData = data
    }

    setComplaintReportdata = (data) => {
        this.complaintReportData = data
    }

    // 
    ChargersLoading(value) {
        this.Chargersloading = value
    }

    ChargersSuccess(value) {
        this.Chargerssuccess = value
    }

    ChargersData(data) {
        this.chargersData = data.response
    }


    //get complaint loading 
    getComplaintTypeLoading = (value) => {
        this.is_get_complaint_type_loading = value
    }

    //get complaint loading 
    getComplaintTypeSuccess = (value) => {
        this.is_get_complaint_type_success = value
    }

    //get complaint loading 
    setComplaintTypeData = (data) => {
        this.getComplaintTypeData = data
    }

    // get complaint status loading
    getComplaintStatusLoading = (value) => {
        this.is_complaint_status_loading = value
    }
    // get complaint status success
    getComplaintStatusSuccess = (value) => {
        this.is_complaint_status_success = value
    }
    // get complaint status success
    setComplaintStatusData = (value) => {
        this.complaintStatusData = value
    }

    // getAllCommentsLoading
    getAllCommentsLoading = (value) => {
        this.is_get_comment_loading = value
    }
    getAllCommentsSuccess = (value) => {
        this.is_get_comment_success = value
    }
    setAllCommentsData = (data) => {
        this.commentsData = data
    }

    // add comment loading
    setAddCommentLoading = (value) => {
        this.is_add_comment_loading = value
    }

    // add comment succcess 
    setAddCommentSuccess = (value) => {
        this.is_add_comment_success = value
    }



    setComplaintError = (error) => {
        this.complaint_error = error
    }

    setComplaintLoading(value) {
        this.is_complaint_loading = value
    }


    getAllPriorityLoading(value) {
        this.is_priority_loading = value
    }

    getAllPrioritySuccess(value) {
        this.is_priority_success = value
    }

    getAllPriorityData(data) {
        this.is_priority_data = data
    }

    // get graph data
    getComplaintsReportData = () => {
        this.setComplaintsReportLoading(true)
        this.setError(undefined)
        HTTPRequest(`${ApiConstants.BASE_URL}complaint/overview`,
            {
                'x-stq-apiKey': this.LoginStore.userInfo.token
            },
            {
                id: this.LoginStore.userInfo.id,

            }).then((res) => {

                this.setComplaintsReportLoading(false)
                if (res.status === 200) {
                    this.setComplaintReportdata(res.response)
                } else {
                    this.setError(res.response.message)
                }

            })
    }
    // get graph data
    getComplaintsGraphData = (start, end) => {
        this.setComplaintsGraphLoading(true)
        this.setError(undefined)
        HTTPRequest(`${ApiConstants.BASE_URL}complaint/complaintsByDates`,
            {
                'x-stq-apiKey': this.LoginStore.userInfo.token
            },
            {
                id: this.LoginStore.userInfo.id,
                startDate: start,
                endDate: end
            }).then((res) => {

                this.setComplaintsGraphLoading(false)
                if (res.status === 200) {
                    this.setComplaintGraphData(res.response)
                } else {
                    this.setError(res.response.message)
                }

            })
    }

    // get all the complaints
    getAllComplaints = (apiRequest, loadable = true) => {
        this.setLoading(loadable)
        this.setError(undefined)
        this.ChargersData([])
        HTTPRequest(`${ApiConstants.BASE_URL}complaint/filterComplaint`,
            {
                'x-stq-apiKey': this.LoginStore.userInfo.token
            },
            {
                id: this.LoginStore.userInfo.id,
                ...apiRequest
            }).then((res) => {
                this.setLoading(false)
                if (res.status === 200) {
                    this.setResponse(res.response)
                } else {
                    this.setError(res.response.message)
                }

            })
    }
    // add complaints
    addComplaints = (data, props) => {
        this.setAddLoading(true)
        this.setError(undefined)
        HTTPRequest(`${ApiConstants.BASE_URL}complaint/addComplaint`,
            {
                'x-stq-apiKey': this.LoginStore.userInfo.token
            },
            {
                "text": data.text,
                "raisedfor": data.raised,
                "complainSubType": data.subType,
                "complainType": data.type,
                "bookingId": data.booking ? data.booking : 0,
                "id": this.LoginStore.userInfo.id,
                "charger_id": data.charger,
                "cpo": data.cpo ? data.cpo : this.companyId,
                "department": data.department,
                "priority_id": data.priority

            }).then((res) => {
                this.setAddLoading(false)
                if (res.status === 200) {
                    this.AlertStore.setAlert('success', res.response.message)
                    props.closeModal()

                } else {
                    this.AlertStore.setAlert('error', res.response.message)
                    this.setError(res.response.message);

                }

            })
    }
    // edit complaints
    editComplaints = (data, props) => {
        this.setAddLoading(true)
        this.setError(undefined)
        HTTPRequest(`${ApiConstants.BASE_URL}complaint/editComplaint`,
            {
                'x-stq-apiKey': this.LoginStore.userInfo.token
            },
            {
                "id": this.LoginStore.userInfo.id,
                "complainSubType": data.subType,
                "complainType": data.type,
                "status_id": data.status.id,
                "refId": data.refId,
                "charger_id": data.charger,
                "comment": data.comment ? data.comment : '',
                "department": data.department,
                "status": data.status.status,
                "priority_id": data.priority

            }).then((res) => {
                this.setAddLoading(false)
                if (res.status === 200) {
                    //this.setResponse(res.response)
                    this.AlertStore.setAlert('success', res.response.message)
                    props.closeModal()

                } else {
                    this.AlertStore.setAlert('error', res.response.message)
                    this.setError(res.response.message)

                }

            })
    }

    //  charger
    chargersUnderStation = (stationId) => {
        this.ChargersLoading(true)
        this.ChargersSuccess(false)
        HTTPGetRequest(`${ApiConstants.BASE_URL}list/chargers-under-station?station_id=${stationId}`,
            {
                'x-stq-apiKey': `${this.LoginStore.userInfo.token}`
            }
        ).then((res) => {
            this.ChargersLoading(false)
            if (res.status === 200) {
                this.ChargersSuccess(true)
                this.ChargersData(res)

            } else {
                this.ChargersSuccess(false)
                this.ChargersData([])
                this.AlertStore.setAlert('error', res.response.message)

            }

        }).catch(e => {
            this.ChargersLoading(false)
        })
    }
    // complaint type
    getComplaintType = () => {
        this.getComplaintTypeLoading(true)
        this.getComplaintTypeSuccess(false)
        HTTPGetRequest(`${ApiConstants.BASE_URL}complaint/listComplaintTypes`,
            {
                'x-stq-apiKey': `${this.LoginStore.userInfo.token}`
            }
        ).then((res) => {
            this.getComplaintTypeLoading(false)
            if (res.status === 200) {
                this.getComplaintTypeSuccess(true)
                this.setComplaintTypeData(res.response)

            } else {
                this.getComplaintTypeSuccess(false)
                this.getComplaintTypeData([])
                this.AlertStore.setAlert('error', res.response.message)

            }

        }).catch(e => {
            this.getComplaintLoading(false)
        })
    }
    // complaint type
    getComplaintStatus = () => {
        this.getComplaintStatusLoading(true)
        this.getComplaintStatusSuccess(false)
        HTTPGetRequest(`${ApiConstants.BASE_URL}complaint/listComplaintStatus`,
            {
                'x-stq-apiKey': `${this.LoginStore.userInfo.token}`
            }
        ).then((res) => {
            this.getComplaintStatusLoading(false)
            if (res.status === 200) {
                this.getComplaintStatusSuccess(true)
                this.setComplaintStatusData(res.response)

            } else {
                this.getComplaintStatusSuccess(false)
                this.getComplaintStatusData([])
                this.AlertStore.setAlert('error', res.response.message)

            }

        }).catch(e => {
            this.getComplaintStatusSuccess(false)
        })
    }

    //  get all the comments
    getAllComment = (id) => {
        this.getAllCommentsLoading(true)
        this.getAllCommentsSuccess(false)
        this.setAddCommentSuccess(false)
        HTTPGetRequest(`${ApiConstants.BASE_URL}complaint/listComments?refId=${id}`,
            {
                'x-stq-apiKey': `${this.LoginStore.userInfo.token}`
            }
        ).then((res) => {
            this.getAllCommentsLoading(false)
            if (res.status === 200) {
                this.getAllCommentsSuccess(true)
                this.setAllCommentsData(res.response.comments)

            } else {
                this.getAllCommentsSuccess(false)
                this.getAllCommentsData([])
                this.AlertStore.setAlert('error', res.response.message)

            }

        }).catch(e => {
            this.getComplaintStatusSuccess(false)
        })
    }

    // add comments
    addComments = (data) => {
        this.setAddCommentLoading(true)
        this.setAddCommentSuccess(false)
        HTTPRequest(`${ApiConstants.BASE_URL}complaint/addComment`,
            {
                'x-stq-apiKey': this.LoginStore.userInfo.token
            },
            {
                id: this.LoginStore.userInfo.id,
                ...data

            }).then((res) => {
                this.setAddCommentLoading(false)
                if (res.status === 200) {
                    this.setAddCommentSuccess(true)
                    this.AlertStore.setAlert('success', res.response.message)


                } else {
                    this.AlertStore.setAlert('error', res.response.message)
                    this.setError(res.response.message);

                }

            })
    }

    // download All the complaint
    downloadComplaint = (apiRequest, loadable = true) => {
        HTTPRequest(`${ApiConstants.BASE_URL}complaint/downloadComplaint`, {
            'x-stq-apiKey': this.LoginStore.userInfo.token
        },
            {
                id: this.LoginStore.userInfo.id,
                ...apiRequest
            }).then((res) => {
                this.setComplaintLoading(false)
                if (res.status === 200) {
                    if(res.response.url || res.response.url !== null){
                        window.open(res.response.url)
                    }
                    else if (!res.response.url || res.response.url === null) {
                        this.AlertStore.setAlert('error', 'No Complaints to download')
                    }
                }
                else {
                    this.AlertStore.setAlert('error', res.response.message)
                    this.setComplaintError(res.response.message)
                }

            }).catch(e => {
                this.setComplaintLoading(false)
            })

    }

    //  get all the comments
    getAllPriority = (id) => {
        this.getAllPriorityLoading(true)
        this.getAllPrioritySuccess(false)
        this.getAllPriorityData([])
        HTTPGetRequest(`${ApiConstants.BASE_URL}complaint/listPriority`,
            {
                'x-stq-apiKey': `${this.LoginStore.userInfo.token}`
            }
        ).then((res) => {
            this.getAllPriorityLoading(false)
            if (res.status === 200) {
                this.getAllPrioritySuccess(true)
                this.getAllPriorityData(res.response.data)

            } else {
                this.getAllPrioritySuccess(false)
                // this.getAllCommentsData([])
                this.AlertStore.setAlert('error', res.response.message)

            }

        }).catch(e => {
            this.getAllPrioritySuccess(false)
        })
    }



}




export default AllComplaintsStore