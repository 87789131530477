import { makeAutoObservable } from "mobx"
import { log } from "../helpers"


class ErrorsStore {
    LoginStore;
    error__loading = false
    success = false
    error = undefined
    response = {}

    constructor(stores) {
        makeAutoObservable(this)
        this.LoginStore = stores.LoginStore
    }
    setLoading(val){
        this.error__loading=val
    }

    getAllErrors = () => {
       this.setLoading(true)
        setTimeout(() => {
            log(this.LoginStore.userInfo)
            this.setLoading(false)
        }, 1000);
    }

}

export default ErrorsStore