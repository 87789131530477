import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './font/MarkPro.ttf';
import reportWebVitals from './reportWebVitals';
import Application from './modules/Application';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
console.disableYellowBox = true;


const theme = createMuiTheme({
  typography: {
    fontFamily: 'MarkPro,sans-serif'
  },
  palette: {
    primary: {
      main: '#101941',
    }
  },


  overrides: {
    MuiButton: {
      root: {},
      label: {
        textTransform: 'none',
      },
      contained: {
        background: 'linear-gradient(180deg, #FC441E 0%, #FE5567 100%)',
        color: '#fff',
        textTransform: 'none',


      },
      containedPrimary: {
        background: 'linear-gradient(180deg, #FC441E 0%, #FE5567 100%)',
        color: '#fff',

      }
    },
    MuiOutlinedInput: {
      root: {
        background: '#F5F6F7',
        position: 'relative',
        '& $notchedOutline': {
          borderColor: '#F5F6F7',
        },
        '& $disabled': {
          color: '#101941',
        },
        '&$focused $notchedOutline': {
          borderColor: '#101941',
          borderWidth: 2,
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#101941',
        },
      },

      input: {
        padding: '14px 14px'
      }
    },
    MuiInputLabel: {
      root: {
        color: '#101941',
        textTransform: 'none',

      }

    },
    MuiFormControl: {
      root: {
        width: '100%'
      }
    },
    MuiFormLabel: {
      root: {
        color: '#101941',
        fontSize: 14,
        marginBottom: 10
      }
    },
    MuiFormControlLabel: {
      root: {
        fontSize: 12,
        color: '#101941',
      }
    },
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: '#12D377',
        },
        '&$active': {
          color: '#12D377',
        },
      }
    },
    MuiStepLabel: {
      root: {},
      label: {
        '&$completed': {
          color: '#12D377',
        },
        '&$active': {
          color: '#12D377',
        },
      }

    }

  },


});
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Application />
    </ThemeProvider>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
