import { makeAutoObservable } from "mobx"
import ApiConstants from "../constants/ApiConstants";
import { HTTPRequest, log} from "../helpers"


class DashboardStore {

    LoginStore;
    user_ov_loading = false
    map_loading = false;
    user_overview = {}
    dashboardReportData = {}
    map_data = []
    map_error = undefined;
    dashboard_error = undefined;
    stationList = []

    dashboardReportloading = false;
    user_overview_error = undefined
    user_overview_success = false
    MODULE_URL = `${ApiConstants.BASE_URL}dashboard/`;


    constructor(stores) {
        makeAutoObservable(this)
        this.LoginStore = stores.LoginStore
    }


    setUserOvLoading(value) {
        this.user_ov_loading = value
    }

    setDashboardReportLoading(value) {
        this.dashboardReportloading = value
    }
    setUserOverview(value) {
        this.user_overview = value
    }
    setOverviewError(value) {
        this.user_overview_error = value
    }

    setDashboardError = (value) =>{
        this.dashboard_error = value
    }


    setDashboardReportData(value) {
        this.dashboardReportData = value
    }
    setOverviewSuccess(value) {
        this.user_overview_success = value
    }

    setMapLoading = (value) => {
        this.map_loading = value;
    }

    setMapData = (data) =>{
        this.map_data = data;
    }

    setMapError = (data) =>{
        this.map_error = data; 
    }

    setStationList = (data)=>{
        this.stationList = data?.station_list
    }

    

    getUserOverView = (start, end, loadable = true) => {
        this.setUserOvLoading(loadable)
         this.setDashboardError(undefined)
        HTTPRequest(`${this.MODULE_URL}complete/response`,
            {
                'x-stq-apiKey': `${this.LoginStore.userInfo.token}`
            }, {
            'id': this.LoginStore.userInfo.id,
            'startDate': start,
            'endDate': end
        }).then((res) => {
            this.setUserOvLoading(false);
            if (res.status === 200) {
                this.setUserOverview(res.response)
            } else {
             this.setDashboardError(res.response.message)
               
            }
        }).catch((err) => {
             this.setDashboardError(err)
            this.setUserOvLoading(false);
        })
    }

    getDashboardReportOverView = () => {
        this.setDashboardReportLoading(true)
        this.setOverviewError(undefined)
        HTTPRequest(`${this.MODULE_URL}vehicleUsage`,
            {
                'x-stq-apiKey': this.LoginStore.userInfo.token
            }, {
            'id': this.LoginStore.userInfo.id,
        }).then((res) => {

            this.setDashboardReportLoading(false);
            if (res.status === 200) {
                this.setDashboardReportData(res.response)
            } else {
                this.setOverviewError(res.response.message)
            }
        }).catch((err) => {
            this.setOverviewError(err)
            this.setDashboardReportLoading(false);
        })
    }

    getLatLong = () => {
        this.setMapLoading(true)
        HTTPRequest(`${this.MODULE_URL}stationMap`, {
            'x-stq-apiKey': this.LoginStore.userInfo.token
        }, {
            'id': this.LoginStore.userInfo.id
        }).then((res) => {
            this.setMapLoading(false);
            if (res.status === 200) {
                this.setMapData(res.response);
            } else {
              this.setMapError(res.response.message);
            }
        }).catch((err) => {
            this.setMapError(err);
            this.setMapLoading(false);
        })
    }

    getStationList = (parmasObj) => {
        HTTPRequest(
            `${this.MODULE_URL}stationList`,
            {
                'x-stq-apiKey': this.LoginStore.userInfo.token,
            },
            {
                id: this.LoginStore.userInfo.id,
                search_key: '',
                city_id : parmasObj?.cityId || [] ,
                state_id: parmasObj?.stateId || []
            }
        )
            .then(res => {
                if (res.status === 200) {
                    this.setStationList(res.response);
                }
            })
            .catch(err => {
                console.log(err);
            });
    };
}

export default DashboardStore
