import { makeAutoObservable } from 'mobx';
import ApiConstants from '../constants/ApiConstants';
import { HTTPRequest, HTTPDeleteRequest, HTTPGetRequest, HTTPPutRequest } from '../helpers';

class ChargerManagementStore {
    LoginStore;
    AlertStore;
    MODULE_URL = `${ApiConstants.BASE_URL}charger/`;
    isLoading = false;
    success = '';
    login__error;
    is_station_error = undefined;
    is_station_log_error = undefined;
    get_error = undefined;
    is_add_charger_success = false;
    allChargersData = {};
    allStationsData = {};
    allStationsLogs = {};
    stationsDetailsData = {};
    companiesList = {};
    userId;
    userToken;
    isGraphLoading = false;
    isBarGraphLoading = false;
    isStationLoading = false;
    isStationDetailLoading = false;
    isChargerReportLoading = false;
    isPieChartDataLoading = false;
    isResetSuccess = false;
    isResetLoading = false;
    isCacheLoading = false;
    isCacheSuccess = false;
    heartBeatData = {};
    barGraphData = {};
    chargerReportData = {};
    chargerPieChartData = {};
    is_edit_success = false;
    isConfigLoading = false;
    isConfigMessage = undefined;
    isConfigSuccess = false;
    is_edit_station_loading = false;
    is_station_success = false;
    updateStationServicesLoading = false;
    updateStationServicesSuccess = false;
    is_edit_charger_loading = false;
    is_add_charger_loading = false;
    is_buy_license_loading = false;
    is_buy_license_success = false;
    is_add_station_loading = false;
    is_add_station_success = false;
    is_edit_station_success = false;
    is_connector_status_loading = false;
    is_connector_status_success = false;
    connectorStatusData = [];
    is_delete_connector_loading = false;
    is_delete_connector_success = false;
    is_charger_dropdown_loading = false;
    is_charger_profile_loading = false;
    is_license_metric_loading = false;
    is_license_cost_loading = false;
    is_charger_session_loading = false;
    is_charger_stakeholder_loading = false;
    is_firmware_loading = false;
    is_diagnostic_loading = false;
    is_firmware_success = false;
    chargerDropdownData = {};
    chargerProfileData = {};
    LicenseMetricData = {};
    LicenseCostData = {};
    chargingSessionData = null;
    buyLicenseData = undefined;
    chargerStakeholderData = {};
    is_direct_start_loading = false;
    is_direct_start_success = false;
    is_add_connector_type_loading = false;
    is_add_connector_type_success = false;
    is_transaction_chart_loading = false;
    transactionChartData = null;
    is_child_charger_loading = false;
    childChargerData = [];
    is_get_company_price_loading = false;
    is_get_company_price_success = false;
    company_price = {};
    is_charger_price_loading = false;
    chargerPriceData = {};
    is_charger_modify_success = false;
    is_station_modify_success = false;
    is_downloadLogsLoading = false;
    is_connector_type_loading = false;
    connectorTypeData = [];
    is_edit_power_rating_loading = false;
    is_edit_power_rating_success = false;
    is_get_config_loading = false;
    is_get_config_success = false;
    getConfigrationData = {};
    price_history_error = undefined;
    allPriceHistoryData = {};
    is_price_history_loading = false;
    is_charger_data_loading = false;
    nonPaginatedChargerData = {};
    is_bulk_update_loading = false;
    price_update_success = false;
    is_charger_uptime_loading = false;
    chargerUptimeData = {};
    chargerMappingList = [];
    chargerMappingLoading = false;
    chargerMappingSuccess = false;
    roamingPriceList = [];
    stationList = [];

    constructor(stores) {
        makeAutoObservable(this);
        this.setLoginStore(stores.LoginStore);
        this.setUserToken(this.LoginStore.userInfo.token);
        this.setAlertStore(stores.AlertStore);
        this.userId = this.LoginStore.userInfo.id;
    }
    setLoginStore(store) {
        this.LoginStore = store;
    }

    setAlertStore(store) {
        this.AlertStore = store;
    }

    setUserToken(val) {
        this.userToken = val;
    }
    // Set loading
    setLoading(value) {
        this.isLoading = value;
    }

    // Set loading
    setCacheLoading(value) {
        this.isCacheLoading = value;
    }
    // set cache success
    setCacheSuccess = value => {
        this.isCacheSuccess = value;
    };

    // Set loading
    setResetLoading(value) {
        this.isResetLoading = value;
    }

    // set add charger loading

    setAddChargerLoading(value) {
        this.is_add_charger_loading = value;
    }

    setLicenseLoading(value) {
        this.is_buy_license_loading = value;
    }
    setLicenseSuccess(data) {
        this.is_buy_license_success = data;
    }
    // Set config loading
    setConfigLoading(value) {
        this.isConfigLoading = value;
    }

    // set config message
    setConfigMessage = data => {
        this.isConfigMessage = data;
    };

    setConfigSuccess = value => {
        this.isConfigSuccess = value;
    };

    // Setting CHanger data

    setChargerData(data) {
        this.allChargersData = data.response;
    }

    setNonPaginatedChargerData(data) {
        this.nonPaginatedChargerData = data.response;
    }

    // Setting station Data
    setStationData(data) {
        this.allStationsData = data.response;
    }

    // Setting station Data
    setStationDetailData(data) {
        this.stationsDetailsData = data;
    }

    // Setting station Logs
    setStationLogs(data) {
        this.allStationsLogs = data.response;
    }
    // Setting Helpers
    setError = error => {
        this.login__error = error;
    };

    // Setting Helpers
    setGetError = error => {
        this.get_error = error;
    };

    setSuccess = val => {
        this.success = val;
    };

    // set edit success
    setEditSuccess = value => {
        this.is_edit_success = value;
    };

    // seteditStationLoading
    seteditStationLoading(value) {
        this.is_edit_station_loading = value;
    }
    // seteditStationSuccess
    seteditStationSuccess(value) {
        this.is_edit_station_success = value;
    }

    // Set add Station loading
    setAddStationLoading(value) {
        this.is_add_station_loading = value;
    }

    // Set add Station loading
    setAddStationSuccess(value) {
        this.is_add_station_success = value;
    }

    //set station Loading
    setStationLoading(val) {
        this.isStationLoading = val;
    }
    //set station Loading
    setStationDetailsLoading(val) {
        this.isStationDetailLoading = val;
    }
    // set update station loading
    setupdateStationServicesLoading(val) {
        this.updateStationServicesLoading = val;
    }
    // set update station success
    setupdateStationServicesSuccess(val) {
        this.updateStationServicesSuccess = val;
    }

    // set graph loading
    setGraphLoading(val) {
        this.isGraphLoading = val;
    }

    // set bargraph loading
    setBarGraphLoading(val) {
        this.isBarGraphLoading = val;
    }

    // set bargraph loading
    setChargerReportLoading(val) {
        this.isChargerReportLoading = val;
    }

    setPieChartDataLoading(val) {
        this.isPieChartDataLoading = val;
    }

    // cache success
    setResetSuccess(val) {
        this.isResetSuccess = val;
    }

    // set station error
    setStationError(val) {
        this.is_station_error = val;
    }

    // set station log eror
    setStationLogError(val) {
        this.is_station_log_error = val;
    }

    // set Graph Data

    setBarGraphData(data) {
        this.barGraphData = data.response;
    }
    setHeartBeatData(data) {
        this.heartBeatData = data.response;
    }
    setChargerReportData(data) {
        this.chargerReportData = data.response;
    }

    setChargerPieChartData(data) {
        this.chargerPieChartData = data.response;
    }

    // set station success
    setStationSuccess(value) {
        this.is_station_success = value;
    }

    // set edit charger loading

    setEditChargerLoading(data) {
        this.is_edit_charger_loading = data;
    }

    // set add charger error
    setAddChargerSuccess(data) {
        this.is_add_charger_success = data;
    }

    // set connector Status LOADING
    setConnectorStatusLoading(value) {
        this.is_connector_status_loading = value;
    }
    // set connector Status success
    setConnectorStatusSuccess(value) {
        this.is_connector_status_success = value;
    }

    // set connector status data
    setConnectorStatusData(data) {
        this.connectorStatusData = data;
    }

    // set delete connector helpers
    setDeleteConnectorLoading(value) {
        this.is_delete_connector_loading = value;
    }

    // set delete connector
    setDeleteConnectorSuccess(value) {
        this.is_delete_connector_success = value;
    }

    // set charger dropdown loading
    setChargerDropdownLoading(value) {
        this.is_charger_dropdown_loading = value;
    }

    // set charger dropdown data
    setChargerDropdownData(data) {
        this.chargerDropdownData = data;
    }
    // set get configuration
    setGetConfigLoading(value) {
        this.is_get_config_loading = value;
    }

    setGetConfigSuccess(value) {
        this.is_get_config_success = value;
    }
    setConfigrationData(data) {
        this.getConfigrationData = data;
    }
    // set firmware
    setFirmwareLoading(value) {
        this.is_firmware_loading = value;
    }
    setDiagnosticLoading(value) {
        this.is_diagnostic_loading = value
    }

    setFirmwareSuccess(value) {
        this.is_firmware_success = value;
    }

    // set add connector type loading
    setAddConnectorTypeLoading(value) {
        this.is_add_connector_type_loading = value;
    }
    // set add connector type loading
    setAddConnectorTypeSuccess(value) {
        this.is_add_connector_type_success = value;
    }

    // set direct start

    setDirectStartLoading(value) {
        this.is_direct_start_loading = value;
    }
    setDirectStartSuccess(value) {
        this.is_direct_start_success = value;
    }

    // set transaction graph
    setTransactionChartLoading(value) {
        this.is_transaction_chart_loading = value;
    }

    setTransactionChartData(data) {
        this.transactionChartData = data;
    }

    // set chargers loading
    setChildChargerLoading(value) {
        this.is_charger_dropdown_loading = value;
    }

    // set child charger data
    setChildChargerData(data) {
        this.childChargerData = data;
    }

    // set get charger price loading
    setCompanyPriceLoading(value) {
        this.is_get_company_price_loading = value;
    }

    // set company price success
    setCompanyPriceSuccess(value) {
        this.is_get_company_price_success = value;
    }

    // set company price data
    setCompanyPrice(data) {
        this.company_price = data;
    }

    // set charger price laoding
    setChargerPriceLoading(value) {
        this.is_charger_price_loading = value;
    }

    // set charger price data
    setChargerPriceData(data) {
        this.chargerPriceData = data;
    }
    // set charger price data
    setChargerModifySuccess(value) {
        this.is_charger_modify_success = value;
    }

    // set charger price data
    setStationModifySuccess(value) {
        this.is_station_modify_success = value;
    }

    // set download logs
    setDownloadLogsLoading(value) {
        this.is_downloadLogsLoading = value;
    }

    // set connector type loading
    setConnectorTypeLoading(value) {
        this.is_connector_type_loading = value;
    }
    // set connector type data
    setConnectorTypeData(data) {
        this.connectorTypeData = data;
    }

    // set power rating loading
    setEditPowerRatingLoading(value) {
        this.is_edit_power_rating_loading = value;
    }

    // set power rating success
    setEditPowerRatingSuccess(value) {
        this.is_edit_power_rating_success = value;
    }

    setChargerStakeholderLoading(value) {
        this.is_charger_stakeholder_loading = value;
    }

    setChargerstakeholderData(data) {
        this.chargerStakeholderData = data;
    }
    setChargingSessionLoading(value) {
        this.is_charger_session_loading = value;
    }

    setChargingSessionData(data) {
        this.chargingSessionData = data;
    }

    // set charger profile loading
    setChargerProfileLoading(value) {
        this.is_charger_profile_loading = value;
    }

    // set charger profile data
    setChargerProfileData(data) {
        this.chargerProfileData = data;
    }
    setLicenseMetricLoading(value) {
        this.is_license_metric_loading = value;
    }

    setLicenseMetricData(data) {
        this.LicenseMetricData = data;
    }

    setLicenseCostLoading(value) {
        this.is_license_cost_loading = value;
    }

    setLicenseCostData(data) {
        this.LicenseCostData = data;
    }
    setBuyLicenseData(data) {
        this.buyLicenseData = data;
    }

    setStationServiceLoading(value) {
        this.is_station_service_loading = value;
    }

    setStationServiceData(data) {
        this.stationServiceData = data;
    }

    //price-history error
    setPriceHistoryError = error => {
        this.price_history_error = error;
    };

    // Setting Price History Data
    setPriceHistoryData(data) {
        this.allPriceHistoryData = data;
    }

    setPriceHistoryLoading(value) {
        this.is_price_history_loading = value;
    }

    // reset licence
    resetLicense = () => {
        this.setLicenseSuccess(false);
    };

    // Set loading
    setNonPaginatedChargerDataLoading(value) {
        this.is_charger_data_loading = value;
    }

    setBulkUpdateLoading(value) {
        this.is_bulk_update_loading = value;
    }

    setBulkUpdateSuccess = value => {
        this.price_update_success = value;
    };

    // set ageing loading
    setChargerUptimeLoading(value) {
        this.is_charger_uptime_loading = value;
    }

    // set ageing data
    setChargerUptimeData(value) {
        this.chargerUptimeData = value;
    }

    setChargerMappingList = value => {
        this.chargerMappingList = value?.chargers_list;
    };

    setChargerMappingLoading = bool => {
        this.chargerMappingLoading = bool;
    };

    setChargerMappingSuccess = bool => {
        this.chargerMappingSuccess = bool;
    };

    setRoamingPriceList = value => {
        this.roamingPriceList = value?.data;
    };

    // set station list
    setStationList = value => {
        this.stationList = value?.station_list
    }

    // get dashboard transaction charts
    getTransactionChart = (start, end, metric) => {
        this.setTransactionChartLoading(true);
        HTTPRequest(
            `${this.MODULE_URL}timeunits/chargingSessionsByDates`,
            {
                'x-stq-apiKey': this.userToken,
            },
            {
                id: this.userId,
                startDate: start,
                endDate: end,
                metric: metric,
            }
        )
            .then(res => {
                this.setTransactionChartLoading(false);
                if (res.status === 200) {
                    this.setTransactionChartData(res.response);
                }
            })
            .catch(err => {
                this.setError(err);
                this.setTransactionChartLoading(false);
            });
    };

    // get dashboard Data
    getChargerReportGraph = filters => {
        this.setChargerReportLoading(true);
        HTTPRequest(
            `${this.MODULE_URL}staticChargerFields`,
            {
                'x-stq-apiKey': this.userToken,
            },
            {
                id: this.userId,
                cityId: filters?.cityId || '',
                stateId: filters?.stateId || '',
                startDate: filters?.startDate || '',
                endDate: filters?.endDate || '',
                charger_id: filters?.chargerId || '',
            }
        )
            .then(res => {
                this.setChargerReportLoading(false);
                if (res.status === 200) {
                    this.setChargerReportData(res);
                } else {
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(err => {
                this.setError(err);
                this.setChargerReportLoading(false);
            });
    };

    // get Pie chart Data
    getChargerPieChartData = filters => {
        this.setPieChartDataLoading(true);
        HTTPGetRequest(`${this.MODULE_URL}funcPieChart?id=${this.userId}`, {
            'x-stq-apiKey': this.userToken,
        })
            .then(res => {
                this.setPieChartDataLoading(false);
                if (res.status === 200) {
                    this.setChargerPieChartData(res);
                } else {
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(err => {
                this.setError(err);
                this.setChargerPieChartData({});
                this.setPieChartDataLoading(true);
            });
    };

    // get dashboard charts
    getHeartbeatGraph = () => {
        this.setGraphLoading(true);
        HTTPRequest(
            `${this.MODULE_URL}ongoingHeartbeatGraph`,
            {
                'x-stq-apiKey': this.userToken,
            },
            {
                id: this.userId,
            }
        )
            .then(res => {
                this.setGraphLoading(false);
                if (res.status === 200) {
                    this.setHeartBeatData(res);
                }
            })
            .catch(err => {
                this.setError(err);
                this.setGraphLoading(false);
            });
    };

    // get dashboard charts
    getBarGraphData = (start, end) => {
        this.setBarGraphLoading(true);
        HTTPRequest(
            `${this.MODULE_URL}chargingSessionsByDates`,
            {
                'x-stq-apiKey': this.userToken,
            },
            {
                id: this.userId,
                startDate: start,
                endDate: end,
            }
        )
            .then(res => {
                this.setBarGraphLoading(false);
                if (res.status === 200) {
                    this.setBarGraphData(res);
                }
            })
            .catch(err => {
                this.setError(err);
                this.setBarGraphLoading(false);
            });
    };

    // reset the data
    resetChildChargerData = () => {
        this.setChildChargerLoading(true);
        this.setChildChargerData([]);
    };

    // getting all charger of a company

    getAllChildChargers = (id, callback) => {
        this.setChildChargerLoading(true);
        this.setChildChargerData([]);
        HTTPGetRequest(`${this.MODULE_URL}list?id=${id}`, {
            'x-stq-apiKey': `${this.userToken}`,
        })
            .then(res => {
                this.setChildChargerLoading(false);
                if (res.status === 200) {
                    callback && callback(res.response);
                    this.setChildChargerData(res.response);
                } else {
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(e => {
                this.setChildChargerLoading(false);
                this.setChildChargerData([]);
                this.AlertStore.setAlert('error', e);
            });
    };

    // getting all charger of a company for coupons

    getAllChildChargersCoupons = (id, callback) => {
        // this.setChildChargerLoading(true)
        // this.setChildChargerData([])
        HTTPGetRequest(`${this.MODULE_URL}list?id=${id}`, {
            'x-stq-apiKey': `${this.userToken}`,
        })
            .then(res => {
                // this.setChildChargerLoading(false)
                if (res.status === 200) {
                    callback(res.response);
                    // this.setChildChargerData(res.response)
                } else {
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(e => {
                // this.setChildChargerLoading(false)
                // this.setChildChargerData([])
                this.AlertStore.setAlert('error', e);
            });
    };

    // Gettings All the chargers
    getAllCharger = (apiRequest, loadable = true) => {
        this.setLoading(loadable);
        this.setGetError(undefined);
        this.setStationDetailsLoading(true);
        this.setEditChargerLoading(false);
        this.setAddChargerLoading(false);
        this.setAddChargerSuccess(false);
        this.setConnectorStatusLoading(true);
        this.setConnectorStatusSuccess(false);
        this.setDeleteConnectorLoading(false);
        this.setDeleteConnectorSuccess(false);
        this.setChargerModifySuccess(false);
        this.setEditPowerRatingSuccess(false);
        this.setBuyLicenseData(undefined);
        HTTPRequest(
            `${this.MODULE_URL}filterChargers`,
            {
                'x-stq-apiKey': `${this.userToken}`,
            },
            {
                // latitude: ApiConstants.BASE_LAT,
                // longitude: ApiConstants.BASE_LONG,
                id: this.userId,
                ...apiRequest,
            }
        )
            .then(res => {
                this.setLoading(false);
                if (res.status === 200) {
                    this.setChargerData(res);
                    this.setEditSuccess(false);
                    this.setSuccess('Success');
                } else {
                    // console.log("RRRRRRRRRRRRR",res.response.message)
                    //this.setChargerData({})
                    this.setGetError(res.response.message);
                }
            })
            .catch(e => {
                this.setLoading(false);
            });
    };
    // download All the chargers
    downloadAllCharger = (apiRequest, loadable = true) => {
        HTTPRequest(
            `${this.MODULE_URL}downloadChargers`,
            {
                'x-stq-apiKey': `${this.userToken}`,
            },
            {
                // latitude: ApiConstants.BASE_LAT,
                // longitude: ApiConstants.BASE_LONG,
                id: this.userId,
                ...apiRequest,
            }
        )
            .then(res => {
                this.setLoading(false);
                if (res.status === 200) {
                    this.AlertStore.setAlert('success', 'Success');
                    window.open(res.response.url);
                } else {
                    // console.log("RRRRRRRRRRRRR",res.response.message)
                    //this.setChargerData({})
                    this.AlertStore.setAlert('error', res.response.message);
                    this.setGetError(res.response.message);
                }
            })
            .catch(e => {
                this.setLoading(false);
            });
    };

    // Getting All the stations

    getAllStations = (loadable = true, apiRequest) => {
        this.setStationLoading(loadable);
        this.setStationError(undefined);
        this.seteditStationLoading(false);
        this.setStationSuccess(false);
        this.setupdateStationServicesSuccess(false);
        this.setAddStationLoading(false);
        this.setAddStationSuccess(false);
        this.setStationModifySuccess(false);
        this.seteditStationSuccess(false);
        HTTPRequest(
            `${this.MODULE_URL}filterStations`,
            {
                'x-stq-apiKey': `${this.userToken}`,
            },
            {
                id: this.userId,
                ...apiRequest,
            }
        )
            .then(res => {
                this.setStationLoading(false);
                this.setStationSuccess(true);
                if (res.status === 200) {
                    this.setStationData(res);
                } else {
                    this.setStationError(res.response.message);
                }
            })
            .catch(e => {
                this.setError(e);
                this.setStationLoading(false);
            });
    };
    // Getting  stations details

    getStationDetails = id => {
        this.setStationDetailsLoading(true);

        HTTPRequest(
            `${this.MODULE_URL}getStationsDetails`,
            {
                'x-stq-apiKey': `${this.userToken}`,
            },
            {
                id: id,
            }
        )
            .then(res => {
                this.setStationDetailsLoading(false);
                if (res.status === 200) {
                    this.setStationDetailData(res.response);
                }
            })
            .catch(e => {
                this.setError(e);
                this.setStationDetailsLoading(false);
            });
    };
    // Getting connector type

    getConnectorsType = () => {
        this.setConnectorTypeLoading(true);
        HTTPGetRequest(`${this.MODULE_URL}get-connector-types`, {
            'x-stq-apiKey': `${this.userToken}`,
        })
            .then(res => {
                this.setConnectorTypeLoading(false);
                if (res.status === 200) {
                    this.setConnectorTypeData(res.response);
                }
            })
            .catch(e => {
                this.setError(e);
                this.setConnectorTypeLoading(false);
            });
    };

    // Getting Stations Logs
    getStationsLogs = (apiRequest, loadable = true) => {
        this.setLoading(loadable);
        this.setStationLogError(undefined);
        HTTPRequest(
            `${this.MODULE_URL}stationLogsFilter2`,
            {
                'x-stq-apiKey': this.userToken,
            },
            {
                id: this.userId,
                ocppId: '',
                ...apiRequest,
            }
        )
            .then(res => {
                this.setLoading(false);
                if (res.status === 200) {
                    this.setStationLogs(res);
                } else {
                    this.setStationLogError(res.response.message);
                }
            })
            .catch(e => {
                this.setError(e);
                this.setLoading(false);
            });
    };

    downloadStationsLogs = data => {
        this.setDownloadLogsLoading(true);
        // this.setDownloadSuccess(false);
        HTTPRequest(
            `${this.MODULE_URL}export-station-logs2`,
            {
                'x-stq-apiKey': this.userToken,
            },
            {
                id: this.userId,
                ...data,
            }
        )
            .then(res => {
                this.setDownloadLogsLoading(false);
                if (res.status === 200) {
                    this.AlertStore.setAlert('success', 'Success');
                    window.open(res.response.url);
                } else {
                    // this.setDownloadSuccess(false);
                    // this.setReportLoading(false)
                    this.AlertStore.setAlert('error', res.response.message || res?.response?.msg);
                }
            })
            .catch(e => {
                // this.setError(e)
                this.setDownloadLogsLoading(false);
                this.AlertStore.setAlert('error', e);
            });
    };

    // Adding a charger
    addCharger = (data, connectorsData) => {
        this.setAddChargerLoading(true);
        this.setAddChargerSuccess(false);

        HTTPRequest(
            `${this.MODULE_URL}addCharger`,
            {
                'x-stq-apiKey': this.userToken,
            },
            {
                id: this.userId,
                name: data.charger_name,
                powerRating: data.pwRating,
                ocppId: data.ocpp_id,
                isFuntional: data.functional ? data.functional : false,
                isBookable: data.bookable,
                numberofslots: data.number_of_conn,
                connectortype: connectorsData,
                stationId: data.charging_station,
                companyPrice: data.companyPrice,
                accessType: data.accessType,
                isHub: data.locationType,
                oemId: data.oem,
                demand_fee: data.demand_fee,
                fixed_cost: data.fixed_cost,
            }
        )
            .then(res => {
                this.setAddChargerLoading(false);
                if (res.status === 200) {
                    this.setSuccess(res.response.message);

                    this.setAddChargerSuccess(true);
                    this.AlertStore.setAlert('success', res.response.message);
                } else {
                    this.setError(res.response.message);

                    this.setAddChargerSuccess(false);

                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(e => {
                this.setError(e);
                this.setAddChargerSuccess(false);

                this.AlertStore.setAlert('error', e);
            });
    };

    // Updating a charger
    updateCharger = (data, loadable = true, isConn = true) => {
        this.setEditSuccess(false);
        this.setEditChargerLoading(true);
        let connectors = [];
        let selectedIds = [];
        // data.chargerPrice?.map((item) => {
        //     selectedIds.push(JSON.stringify(item.id));
        //     if (item.child.length > 0) {
        //         item.child.map((data) => {
        //             selectedIds.push(JSON.stringify(data.id))
        //         })
        //     }
        // })
        if (isConn) {
            for (let i = 0; i < data.number_of_conn; i++) {
                connectors.push(data['conn_type_' + i]);
            }
        }

        HTTPRequest(
            `${this.MODULE_URL}editCharger`,
            {
                'x-stq-apiKey': this.userToken,
            },

            {
                adminId: this.userId,
                name: data.charger_name,
                companyPrice: data.companyPrice,
                ocppId: data.ocpp_id,

                numberofslots: data.number_of_conn,
                // "connectortype": connectors,
                stationId: data.charging_station,
                id: data.id,
                companyIds: data.companyIds,
                accessType: data.accessType,
                isHub: data.locationType,
                oemId: data.oem,
                demand_fee: data.demand_fee,
                fixed_cost: data.fixed_cost,
                new_charger_name: data?.new_charger_name,
            }
        )
            .then(res => {
                this.setEditChargerLoading(false);
                if (res.status === 200) {
                    this.setEditSuccess(true);
                    this.AlertStore.setAlert('success', res.response.message);
                } else {
                    this.setEditSuccess(false);
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(e => {
                this.setEditChargerLoading(false);
                this.setEditSuccess(false);
                this.AlertStore.setAlert('error', e);
            });
    };

    // charger statys

    modifyChargerStatus = (id, bookable, func, reload) => {
        this.setChargerModifySuccess(false);

        HTTPRequest(
            `${this.MODULE_URL}modify-charger-flag`,
            {
                'x-stq-apiKey': `${this.userToken}`,
            },
            {
                chargerId: id,
                isBookable: bookable,
                isFunctional: func,
            }
        )
            .then(res => {
                if (res.status === 200) {
                    this.setChargerModifySuccess(true);
                    reload();
                    this.AlertStore.setAlert('success', res.response.message);
                } else {
                    this.setChargerModifySuccess(false);
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(e => {
                this.setChargerModifySuccess(false);
                this.AlertStore.setAlert('error', e);
            });
    };

    modifyStationStatus = (id, isVisible) => {
        this.setStationModifySuccess(false);

        HTTPRequest(
            `${this.MODULE_URL}modify-station-flag`,
            {
                'x-stq-apiKey': `${this.userToken}`,
            },
            {
                stationId: id,
                isVisible: isVisible,
            }
        )
            .then(res => {
                if (res.status === 200) {
                    this.setStationModifySuccess(true);
                    // reload()
                    this.AlertStore.setAlert('success', res.response.message);
                } else {
                    this.setStationModifySuccess(false);
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(e => {
                this.setStationModifySuccess(false);
                this.AlertStore.setAlert('error', e);
            });
    };

    // Add a station
    addStation = data => {
        this.setAddStationLoading(true);
        this.setAddStationSuccess(false);
        HTTPRequest(
            `${this.MODULE_URL}addStation`,
            {
                'x-stq-apiKey': this.userToken,
            },
            {
                admin_id: this.userId,
                name: data.station_name,
                latitude: data.latitude,
                longitude: data.longitude,
                area: data.area,
                address: data.address,
                openTime: data.open_time,
                closeTime: data.close_time,
                companyId: data.company,
                isVisible: data.is_Visible,
                street: data.street,
                cityId: data.city,
                // "companyId": data.company,
                stateId: data.state,
                pincode: data.pincode,
                services: data.services,
                cluster: data.cluster,
                site_code: data.site_code,
                is_open: data.is_open,
            }
        )
            .then(res => {
                this.setLoading(false);
                this.setAddStationLoading(false);
                if (res.status === 200) {
                    this.setAddStationSuccess(true);
                    this.AlertStore.setAlert('success', res.response.message);
                    this.setAddStationSuccess(false);
                } else {
                    this.setAddStationSuccess(false);
                    this.AlertStore.setAlert('error', res.response.message);
                    this.setAddStationSuccess(false);
                }
            })
            .catch(e => {
                this.setError(e);
                this.setAddStationLoading(false);
                this.setAddStationSuccess(false);
            });
    };
    // Update a station
    updateStation = data => {
        this.seteditStationLoading(true);
        this.seteditStationSuccess(false);
        HTTPRequest(
            `${this.MODULE_URL}editstation`,
            {
                'x-stq-apiKey': this.userToken,
            },
            {
                admin_id: this.userId,
                name: data.station_name,
                station_name: data?.new_station_name,
                latitude: data.latitude,
                longitude: data.longitude,
                pwRating: data.power_units,
                type: data.type,
                area: data.area,
                address: data.address,
                openTime: data.open_time,
                closeTime: data.close_time,
                companyId: data.company,
                id: data.id,
                is_visible: data.is_visible,
                street: data.street,
                cityId: data.city,
                // "companyId": data.company,
                stateId: data.state,
                pincode: data.pincode,
                cluster: data.cluster,
                site_code: data.site_code,
                is_open: data?.is_open,
            }
        )
            .then(res => {
                this.seteditStationLoading(false);
                if (res.status === 200) {
                    this.seteditStationSuccess(true);
                    this.AlertStore.setAlert('success', res.response.message);
                } else {
                    this.setError(res.response.message);
                    this.seteditStationSuccess(false);
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(e => {
                this.setError(e);
                this.seteditStationSuccess(false);
                this.seteditStationLoading(false);
            });
    };

    // Adding services to station
    updateStationServices = data => {
        this.setupdateStationServicesLoading(true);
        this.setupdateStationServicesSuccess(false);
        HTTPRequest(
            `${this.MODULE_URL}station/updateServices`,
            {
                'x-stq-apiKey': this.userToken,
            },
            {
                stationId: data.stationId,
                services: data.services,
            }
        )
            .then(res => {
                this.setupdateStationServicesLoading(false);
                if (res.status === 200) {
                    this.setupdateStationServicesSuccess(true);
                    this.AlertStore.setAlert('success', res.response.message);
                } else {
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(e => {
                this.AlertStore.setAlert('error', e);
                this.setupdateStationServicesLoading(false);
            });
    };

    // Clear Cache
    clearCache = id => {
        this.setCacheLoading(true);
        this.setCacheSuccess(false);
        HTTPRequest(
            `${this.MODULE_URL}clearcache`,
            {
                'x-stq-apiKey': this.userToken,
            },
            {
                charger_id: id,
            }
        )
            .then(res => {
                this.setCacheLoading(false);
                if (res.status === 200) {
                    this.setCacheSuccess(true);
                    this.AlertStore.setAlert('success', res.response.message);
                } else {
                    this.setCacheSuccess(false);
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(e => {
                this.setCacheSuccess(false);
                this.setCacheLoading(false);
                this.AlertStore.setAlert('error', e);
            });
    };
    // Reset Charger
    resetCharger = id => {
        this.setResetLoading(true);
        this.setResetSuccess(false);
        HTTPRequest(
            `${this.MODULE_URL}reset`,
            {
                'x-stq-apiKey': this.userToken,
            },
            {
                charger_id: id,
            }
        )
            .then(res => {
                this.setResetLoading(false);
                if (res.status === 200) {
                    this.setResetSuccess(true);
                    this.AlertStore.setAlert('success', res.response.message);
                } else {
                    this.setResetSuccess(false);
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(e => {
                this.setResetSuccess(false);
                this.setResetLoading(false);
                this.AlertStore.setAlert('error', e);
            });
    };

    // Config Charger
    configCharger = (id, apiRequest) => {
        this.setConfigLoading(true);
        this.setConfigMessage(undefined);
        this.setConfigSuccess(false);
        HTTPRequest(
            `${this.MODULE_URL}config_changes`,
            {
                'x-stq-apiKey': this.userToken,
            },
            {
                ...apiRequest,
                charger_id: id,
            }
        )
            .then(res => {
                this.setConfigLoading(false);
                if (res.status === 200) {
                    this.setConfigSuccess(true);
                    this.AlertStore.setAlert('success', res.response.message);
                } else {
                    this.setConfigSuccess(false);
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(e => {
                this.setConfigSuccess(false);
                this.setConfigLoading(false);
                this.AlertStore.setAlert('error', e);
            });
    };
    // Charger Connector Status
    chargerConnectorStatus = (id, loadable = true) => {
        this.setConnectorStatusLoading(loadable);
        this.setConnectorStatusSuccess(false);
        HTTPRequest(
            `${this.MODULE_URL}getChargerConnectors`,
            {
                'x-stq-apiKey': this.userToken,
            },
            {
                id: id,
            }
        )
            .then(res => {
                this.setConnectorStatusLoading(false);
                if (res.status === 200) {
                    this.setConnectorStatusData(res.response);
                    this.setConnectorStatusSuccess(true);
                } else {
                    this.setConnectorStatusSuccess(false);
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(e => {
                this.setConnectorStatusSuccess(false);
                this.setConnectorStatusLoading(false);
                this.AlertStore.setAlert('error', e);
            });
    };
    // get configuration

    getConfiguration = (data, chargerId) => {
        this.setGetConfigLoading(true);
        this.setGetConfigSuccess(false);
        HTTPRequest(
            `${this.MODULE_URL}get_config_keys`,
            {
                'x-stq-apiKey': this.userToken,
                charger_id: chargerId,
            },
            {
                charger_id: data.id,
            }
        )
            .then(res => {
                this.setGetConfigLoading(false);
                if (res.status === 200) {
                    this.setGetConfigSuccess(true);
                    this.setConfigrationData(res.response);
                    this.AlertStore.setAlert('success', res.response.message);
                } else {
                    this.setGetConfigSuccess(false);
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(e => {
                this.setGetConfigSuccess(false);
                this.setGetConfigLoading(false);
                this.AlertStore.setAlert('error', e);
            });
    };

    // upgrade firmware

    updateFirmwareURL = (data, chargerId) => {
        this.setFirmwareLoading(true);
        this.setFirmwareSuccess(false);
        HTTPRequest(
            `${this.MODULE_URL}upgrade_firmware`,
            {
                'x-stq-apiKey': this.userToken,
                charger_id: chargerId,
            },
            {
                charger_id: data.id,
                url: data.url,
            }
        )
            .then(res => {
                this.setFirmwareLoading(false);
                if (res.status === 200) {
                    this.setFirmwareSuccess(true);
                    this.AlertStore.setAlert('success', res.response.message);
                } else {
                    this.setFirmwareSuccess(false);
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(e => {
                this.setFirmwareSuccess(false);
                this.setFirmwareLoading(false);
                this.AlertStore.setAlert('error', e);
            });
    };

    // Diagnostic Log
    diagnosticLogs = params => {
        this.setDiagnosticLoading(true)
        HTTPRequest(
            `${this.MODULE_URL}diagnostic_logs`,
            {
                'x-stq-apiKey': this.userToken
            },
            {
                ...params
            }
        )
            .then(res => {
                this.setDiagnosticLoading(false);
                if (res.status === 200) {
                    this.AlertStore.setAlert('success', res.response.message);
                } else {
                    this.AlertStore.setAlert('error', res.response.message)
                }
            })
            .catch(e => {
                this.setDiagnosticLoading(false);
                this.AlertStore.setAlert('error', e);
            });
    }

    // get All chargers

    getChargerDropDown = paramsObj => {
        // this.setChargerDropdownLoading(true);
        paramsObj.page === 1 && this.setChargerDropdownData([]);
        HTTPGetRequest(
            `${this.MODULE_URL}search/charger?id=${this.userId}&page=${paramsObj.page}&search=${
                paramsObj.search
            }&station_id=${paramsObj?.stationId || ''}&state_id=${paramsObj.stateId || ''}&city_id=${
                paramsObj.cityId || ''
            }&power_rating=${paramsObj?.charger_rating || ''}&cpo_id=${paramsObj?.cpoId || ''}`,
            {
                'x-stq-apiKey': this.userToken,
            }
        )
            .then(res => {
                if (res.status === 200) {
                    // this.setChargerDropdownLoading(false);
                    this.setChargerDropdownData(res.response);
                } else {
                    // this.setChargerDropdownLoading(false);
                    if (
                        res.status === 400 &&
                        res?.response?.message === 'Chargers are not present' &&
                        paramsObj?.page === 1
                    ) {
                        this.setChargerDropdownData({ chargers_list: [], total_pages: 0 });
                    }
                    this.AlertStore.setAlert('error', res.message || res?.response?.message);
                }
            })
            .catch(e => {
                // this.setChargerDropdownLoading(false);
                this.AlertStore.setAlert('error', e);
            });
    };

    // Non paginated api for get All chargers

		chargerListSelectAll = (paramsObj) => {
			this.setChargerDropdownLoading(true);
			this.setChargerDropdownData({ chargers_list: [], total_pages: 0 });
			HTTPGetRequest(
				`${this.MODULE_URL}chargerListSelectAll?id=${this.userId}&search=${paramsObj?.search || ''}&station_id=${paramsObj?.stationId || ''}&state_id=${paramsObj?.stateId || ''}&city_id=${paramsObj?.cityId || ''}&power_rating=${paramsObj?.charger_rating || ''}&cpo_id=${paramsObj?.cpoId || ''}`,
				{
					"x-stq-apiKey": this.userToken,
				}
			)
				.then((res) => {
					if (res.status === 200) {
						this.setChargerDropdownData({
							chargers_list : res?.response?.chargers
						});
						this.setChargerDropdownLoading(false);
					} else {
								this.setChargerDropdownLoading(false);
						if (res.status === 400 ) {
							this.setChargerDropdownData({ chargers_list: [], total_pages: 0 });
						}
						this.AlertStore.setAlert(
							"error",
							res.message || res?.response?.message
						);
					}
				})
				.catch((e) => {
					this.setChargerDropdownLoading(false);
					this.AlertStore.setAlert("error", e);
				});
		};

    //  Delete connectors

    deleteConnector = id => {
        this.setDeleteConnectorLoading(true);
        this.setDeleteConnectorSuccess(false);
        HTTPDeleteRequest(`${this.MODULE_URL}deleteChargerConnectors/${id}`, {
            'x-stq-apiKey': this.userToken,
        })
            .then(res => {
                this.setDeleteConnectorLoading(false);
                if (res.status === 200) {
                    this.setDeleteConnectorSuccess(true);
                    this.AlertStore.setAlert('success', res.message);
                } else {
                    this.setDeleteConnectorSuccess(false);
                    this.AlertStore.setAlert('error', res.message);
                }
            })
            .catch(e => {
                this.setDeleteConnectorLoading(false);
                this.AlertStore.setAlert('error', e);
            });
    };

    // Add of connectors

    addConnectorType = data => {
        this.setAddConnectorTypeLoading(true);
        this.setAddConnectorTypeSuccess(false);
        HTTPPutRequest(
            `${this.MODULE_URL}addChargerConnectors`,
            {
                'x-stq-apiKey': this.userToken,
            },
            {
                ...data,
            }
        )
            .then(res => {
                this.setAddConnectorTypeLoading(false);
                if (res.status === 200) {
                    this.AlertStore.setAlert('success', res.response.message);
                    this.setAddConnectorTypeSuccess(true);
                } else {
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(e => {
                this.setAddConnectorTypeLoading(false);
                this.AlertStore.setAlert('error', e);
            });
    };
    // Edit of PowerRating

    editPowerRating = data => {
        this.setEditPowerRatingLoading(true);
        this.setEditPowerRatingSuccess(false);
        HTTPPutRequest(
            `${this.MODULE_URL}edit-power-rating`,
            {
                'x-stq-apiKey': this.userToken,
            },
            {
                ...data,
            }
        )
            .then(res => {
                this.setEditPowerRatingLoading(false);
                if (res.status === 200) {
                    this.AlertStore.setAlert('success', res.response.message);
                    this.setEditPowerRatingSuccess(true);
                } else {
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(e => {
                this.setEditPowerRatingLoading(false);
                this.AlertStore.setAlert('error', e);
            });
    };

    // Direct Start connector id

    directStartConnector = data => {
        this.setDirectStartLoading(true);
        this.setDirectStartSuccess(false);
        HTTPGetRequest(`${ApiConstants.BASE_URL}direct/start/${data.custId}/${data.chargerId}/${data.connectorId}`, {
            'x-stq-apiKey': this.userToken,
        })
            .then(res => {
                this.setDirectStartLoading(false);
                if (res.status === 200) {
                    this.setDirectStartSuccess(true);
                    // this.AlertStore.setAlert('success', res.response.message)
                } else {
                    this.AlertStore.setAlert('error', res.message);
                }
            })
            .catch(e => {
                this.setDirectStartLoading(false);
                this.AlertStore.setAlert('error', e);
            });
    };

    // get charger price

    getChargerPrice = id => {
        this.setChargerPriceLoading(true);
        this.setChargerPriceData([]);
        HTTPGetRequest(`${this.MODULE_URL}company-price?id=${this.userId}&chargerId=${id}`, {
            'x-stq-apiKey': `${this.userToken}`,
        })
            .then(res => {
                this.setChargerPriceLoading(false);
                if (res.status === 200) {
                    this.setChargerPriceData(res.response);
                } else {
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(e => {
                this.setChargerPriceLoading(false);
                this.AlertStore.setAlert('error', e);
            });
    };

    getChargerStakeholder = id => {
        this.setChargerStakeholderLoading(true);
        HTTPGetRequest(`${ApiConstants.BASE_URL}common/charger-stakeholder?id=${this.userId}&chargerId=${id}`, {
            'x-stq-apiKey': this.userToken,
        })
            .then(res => {
                this.setChargerStakeholderLoading(false);
                if (res.status === 200) {
                    this.setChargerstakeholderData(res.response);
                } else {
                    this.AlertStore.setAlert('error', res.message);
                }
            })
            .catch(e => {
                this.setChargerStakeholderLoading(false);
                this.AlertStore.setAlert('error', e);
            });
    };

    getChargingSessionByDate = (start, end, metric, chargerId) => {
        this.setChargingSessionLoading(true);
        HTTPRequest(
            `${this.MODULE_URL}timeunits/chargingSessionsByDates`,
            {
                'x-stq-apiKey': this.userToken,
            },
            {
                id: this.userId,
                startDate: start,
                endDate: end,
                metric: metric,
                charger_id: chargerId,
            }
        )
            .then(res => {
                this.setChargingSessionLoading(false);
                if (res.status === 200) {
                    this.setChargingSessionData(res.response);
                } else {
                    this.AlertStore.setAlert('error', res.message);
                }
            })
            .catch(e => {
                this.setChargingSessionLoading(false);
                this.AlertStore.setAlert('error', e);
            });
    };

    getChargerProfileInfo = id => {
        this.setChargerProfileLoading(true);
        HTTPGetRequest(`${this.MODULE_URL}details?charger_id=${id}`, {
            'x-stq-apiKey': this.userToken,
        })
            .then(res => {
                this.setChargerProfileLoading(false);
                if (res.status === 200) {
                    this.setChargerProfileData(res.response);
                } else {
                    this.AlertStore.setAlert('error', res.message);
                }
            })
            .catch(e => {
                this.setChargerProfileLoading(false);
                this.AlertStore.setAlert('error', e);
            });
    };

    getLicenseMetrix = id => {
        this.setLicenseMetricLoading(true);
        this.setLicenseSuccess(false);
        HTTPGetRequest(`${this.MODULE_URL}metric?admin_id=${id}`, {
            'x-stq-apiKey': this.userToken,
        })
            .then(res => {
                this.setLicenseMetricLoading(false);
                if (res.status === 200) {
                    this.setLicenseMetricData(res.response);
                } else {
                    this.AlertStore.setAlert('error', res.message);
                }
            })
            .catch(e => {
                this.setLicenseMetricLoading(false);
                this.AlertStore.setAlert('error', e);
            });
    };

    getLicenseCost = id => {
        this.setLicenseCostLoading(true);
        HTTPGetRequest(`${this.MODULE_URL}licence-cost?admin_id=${id}`, {
            'x-stq-apiKey': this.userToken,
        })
            .then(res => {
                this.setLicenseCostLoading(false);
                if (res.status === 200) {
                    this.setLicenseCostData(res.response);
                } else {
                    this.AlertStore.setAlert('error', res.message);
                }
            })
            .catch(e => {
                this.setLicenseCostLoading(false);
                this.AlertStore.setAlert('error', e);
            });
    };

    buyLicense = (licenseNumber, LicenseCheck, companyId) => {
        this.setLicenseLoading(true);
        this.setLicenseSuccess(false);
        this.setBuyLicenseData(undefined);
        HTTPRequest(
            `${this.MODULE_URL}licence-log`,
            {
                'x-stq-apiKey': this.userToken,
            },
            {
                count: parseInt(licenseNumber),
                admin_id: this.userId,
                company_id: companyId,
                licence_type: LicenseCheck,
            }
        )
            .then(res => {
                this.setLicenseLoading(false);
                if (res.status === 200) {
                    this.setLicenseSuccess(true);
                    this.setBuyLicenseData(res.response);
                    this.AlertStore.setAlert('success', res.response.message);
                } else {
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(e => {
                this.AlertStore.setAlert('error', e);
                this.setLicenseLoading(false);
            });
    };

    getStationService = () => {
        this.setStationServiceLoading(true);
        HTTPGetRequest(`${this.MODULE_URL}listStationServices`, {
            'x-stq-apiKey': this.userToken,
        })
            .then(res => {
                this.setStationServiceLoading(false);
                if (res.status === 200) {
                    this.setStationServiceData(res.response);
                } else {
                    this.AlertStore.setAlert('error', res.message);
                }
            })
            .catch(e => {
                this.setStationServiceLoading(false);
                this.AlertStore.setAlert('error', e);
            });
    };
    // get all the Price History
    getPriceHistory = (apiRequest, loadable = true) => {
        this.setPriceHistoryLoading(loadable);
        this.setPriceHistoryError(undefined);
        HTTPRequest(
            `${this.MODULE_URL}listChargerPriceLogs`,
            {
                'x-stq-apiKey': this.userToken,
            },
            {
                id: this.LoginStore.userInfo.id,
                ...apiRequest,
            }
        )
            .then(res => {
                this.setPriceHistoryLoading(false);
                if (res.status === 200) {
                    this.setPriceHistoryData(res.response);
                } else {
                    this.setPriceHistoryError(res.response.message);
                }
            })
            .catch(e => {
                this.setPriceHistoryError(e);
                this.setPriceHistoryLoading(false);
            });
    };

    // download All the Price History
    downloadPriceHistory = (apiRequest, loadable = true) => {
        HTTPRequest(
            `${this.MODULE_URL}downloadChargerPriceLogs`,
            {
                'x-stq-apiKey': `${this.userToken}`,
            },
            {
                id: this.userId,
                ...apiRequest,
            }
        )
            .then(res => {
                this.setPriceHistoryLoading(false);
                if (res.status === 200) {
                    if (res.response.url) {
                        this.AlertStore.setAlert('success', 'Success');
                        window.open(res.response.url);
                    } else {
                        this.AlertStore.setAlert('error', 'No data to download');
                    }
                } else {
                    this.AlertStore.setAlert('error', res.response.message);
                    this.setPriceHistoryError(res.response.message);
                }
            })
            .catch(e => {
                this.setPriceHistoryLoading(false);
            });
    };

    //Non Paginated chargers data
    getNonPaginatedChargerData = apiRequest => {
        this.setNonPaginatedChargerDataLoading(true);
        HTTPRequest(
            `${this.MODULE_URL}filterChargerBulkPrice`,
            {
                'x-stq-apiKey': `${this.userToken}`,
            },
            {
                id: this.userId,
                ...apiRequest,
            }
        )
            .then(res => {
                this.setNonPaginatedChargerDataLoading(false);
                if (res.status === 200) {
                    this.setNonPaginatedChargerData(res);
                } else {
                    this.setGetError(res.response.message);
                }
            })
            .catch(e => {
                this.setNonPaginatedChargerDataLoading(false);
            });
    };

    downloadSelectedCharger = payload => {
        HTTPRequest(
            `${this.MODULE_URL}downloadBulkChargersList`,
            {
                'x-stq-apiKey': `${this.userToken}`,
            },
            payload
        )
            .then(res => {
                if (res.status === 200) {
                    this.AlertStore.setAlert('success', 'Success');
                    window.open(res.response.url);
                } else {
                    this.AlertStore.setAlert('error', res.response.message);
                    this.setGetError(res.response.message);
                }
            })
            .catch(e => {});
    };

    // Price Bulk Update
    bulkUpdatePrice = payload => {
        this.setBulkUpdateLoading(true);
        this.setBulkUpdateSuccess(false);
        HTTPRequest(
            `${this.MODULE_URL}getBulkChargerData`,
            {
                'x-stq-apiKey': this.userToken,
            },
            { ...payload, admin_id: this.userId }
        )
            .then(res => {
                this.setBulkUpdateLoading(false);
                if (res.status === 200) {
                    this.setBulkUpdateSuccess(true);
                    this.AlertStore.setAlert('success', res.response.message);
                } else {
                    this.setBulkUpdateSuccess(false);
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(e => {
                this.AlertStore.setAlert('error', e);
                this.setBulkUpdateLoading(false);
            });
    };

    getChargerUptimeData = id => {
        this.setChargerUptimeLoading(true);
        HTTPGetRequest(`${this.MODULE_URL}aging-report?chargerId=${id}`, {
            'x-stq-apiKey': this.userToken,
        })
            .then(res => {
                this.setChargerUptimeLoading(false);
                if (res.status === 200) {
                    this.setChargerUptimeData(res.response);
                } else {
                    this.AlertStore.setAlert('error', res.message);
                }
            })
            .catch(e => {
                this.setChargerUptimeLoading(false);
                this.AlertStore.setAlert('error', e);
            });
    };

    getBulkMappingChargers = data => {
        this.setChargerMappingLoading(true);
        HTTPGetRequest(
            `${this.MODULE_URL}search/roaming_chargers?id=${this.userId}&search=${data?.search || ''}&station_id=${
                data?.stationId
            }
		&state_id=${data?.stateId || ''}&city_id=${data?.cityId || ''}&is_emsp=${data?.isEmsp}&comp_id=${data?.compId}&power_rating=${data?.charger_rating}&cpo_id=${data?.cpoId}`,
            {
                'x-stq-apiKey': this.userToken,
            }
        )
            .then(res => {
                this.setChargerMappingLoading(false);
                if (res.status === 200) {
                    this.setChargerMappingList(res?.response);
                } else {
                    this.setChargerMappingList([]);
                    this.AlertStore.setAlert('error', res.response?.message);
                }
            })
            .catch(e => {
                this.setChargerMappingList([]);
                this.setChargerMappingLoading(false);
                this.AlertStore.setAlert('error', e);
            });
    };

    mapChargers = data => {
        this.setChargerMappingSuccess(false);
        HTTPRequest(
            `${this.MODULE_URL}map_chargers`,
            {
                'x-stq-apiKey': `${this.userToken}`,
            },
            {
                admin_id: this.userId,
                ...data,
            }
        )
            .then(res => {
                if (res.status === 200) {
                    this.setChargerMappingSuccess(true);
                    this.AlertStore.setAlert('success', res.response.message);
                    this.setChargerMappingSuccess(false);
                } else {
                    this.setChargerMappingSuccess(false);
                    this.AlertStore.setAlert('error', res.response.message);
                }
            })
            .catch(e => {
                this.setChargerMappingSuccess(false);
            });
    };

    getRoamingPrice = id => {
        HTTPGetRequest(`${this.MODULE_URL}roaming-price?chargerId=${id}`, {
            'x-stq-apiKey': this.userToken,
        })
            .then(res => {
                if (res.status === 200) {
                    this.setRoamingPriceList(res?.response);
                } else {
                    this.setRoamingPriceList([]);
                }
            })
            .catch(e => {
                this.setRoamingPriceList([]);
            });
    };

    // get stations list
    getStationList = () => {
        HTTPGetRequest(`${ApiConstants.BASE_URL}chargers/list-filter-station`, {
            'x-stq-apiKey': this.userToken,
        })
        .then(res => {
            if (res.status === 200) {
                this.setStationList(res?.response);
            } else {
                this.setStationList([]);
            }
        })
        .catch(e => {
            this.setStationList([]);
        });
    }
}

export default ChargerManagementStore;
