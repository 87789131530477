import { makeAutoObservable } from "mobx"

class AlertStore {
    alert_msg = undefined;
    alert_type = undefined;
    alert_loading = false;


    constructor() {
        makeAutoObservable(this)
    }

    setAlertMsg(msg) {
        this.alert_msg = msg
    }

    setAlertType(type) {
        this.alert_type = type
    }
    setAlertLoading(value) {
        this.alert_loading = value
    }

    setAlert = (type, msg) => {
        this.setAlertLoading(true)
        this.setAlertMsg(msg)
        this.setAlertType(type)
        setTimeout(() => {
            this.setAlertLoading(false)
        }, 1500)
    }

}

export default AlertStore